import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import myAccountServiceClient from './MyAccountServiceClient.js'
import customerServiceClient from './CustomerServiceClient.js'

const {
  public: {
    myAccountSorpEnabled,
  },
} = useRuntimeConfig()

const {
  myAccount: {
    features: {
      myAccountSorpFromCustomerServiceEnabled,
    },
  },
} = useAppConfig()

export const isSorpCustomerService = myAccountSorpEnabled && myAccountSorpFromCustomerServiceEnabled
export const client = isSorpCustomerService ? customerServiceClient : myAccountServiceClient

function createSuklWidgetConfig() {
  var codes = [];
  var medicalProdIds = [];
  var vetProdIds = [];
  var certificates = [{
    image: {
      url: "/images/footer/sukl.png",
      alt: "Krajowy Rejestr Aptek",
      width: 120,
      height: 100
    },
    link: "https://rejestrymedyczne.ezdrowie.gov.pl/ra/pharmacy/public/details/1219675"
  }];
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());
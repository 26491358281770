import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { resolveCmsBlogAuthorById } from '@nsf/cms/repositories/CmsServiceRepository.js'

export default function () {
  registerPageResolver(
    'CMS_SERVICE_BLOG_AUTHOR',
    resolveCmsBlogAuthorById,
    () => import('@nsf/articles/pages/authors/_author.vue'),
  )
}

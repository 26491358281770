import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('homeDeliveries')
  .toDeliveryCalculator()
  .where('destinationPostcode', variable('destinationPostcode', 'String!'))
  .where('products', variable('products', '[ProductInput!]'))
  .where('deliveryMethods', variable('deliveryMethods', '[Int!]'))
  .fields([
    field('deliveryMethod'),
    field('available'),
    field('deliveryTimes', [
      field('cutoffTime'),
      field('deliveryTime'),
      field('deliveryPlan', [
        field('dropTime'),
        field('primarySource', [
          field('connection', [
            field('deliveryRouteId'),
            field('sourceId'),
            field('sourceType'),
            field('destinationId'),
            field('deliveryMethod'),
          ]),
        ]),
      ]),
    ]),
  ])

/* eslint-disable import/prefer-default-export */
import { refreshToken } from '../repositories/authRepository.js'
import {
  removeTokens, getAuthorization,
} from './auth.js'
import { isUnauthorizedStatus } from './serverErrorStatus.js'

const enrichRequestArgsWithAuth = (argumentCount, args) => {
  const newArgs = []
  const headers = {
    Authorization: args[1]?.headers?.Authorization || getAuthorization(),
  }

  for (let i = 0; i <= argumentCount; i++) {
    newArgs.push(args[i])
  }

  newArgs[argumentCount] = {
    ...newArgs[argumentCount],
    headers: {
      ...newArgs[argumentCount]?.headers,
      ...headers,
    },
  }

  return newArgs
}

export function withAuth(requestFn) {
  return async function (...args) {
    let response = await requestFn(
      ...enrichRequestArgsWithAuth(requestFn.length, args),
    )

    if (!response.ok && isUnauthorizedStatus(response.status)) {
      if (response.data?.detail === 'INVALID_OR_MISSING_MY_ACCOUNT_TOKEN') {
        removeTokens()
      }

      const { ok } = await refreshToken()

      if (ok) {
        response = await requestFn(...enrichRequestArgsWithAuth(requestFn.length, args))
      }
    }

    return response
  }
}

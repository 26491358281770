import unsupportedBrowser from "#unsupported-browser";
export default function (event) {
  var _useLegacyBrowsersDet = useLegacyBrowsersDetection(event),
    isBrowser = _useLegacyBrowsersDet.isBrowser,
    isUnsupported = _useLegacyBrowsersDet.isUnsupported;
  var _useLegacyBrowsersOpt = useLegacyBrowsersOptions(),
    serveUnsupportedOnNoUA = _useLegacyBrowsersOpt.serveUnsupportedOnNoUA;
  if (isUnsupported || !isBrowser && serveUnsupportedOnNoUA) {
    if (!unsupportedBrowser) {
      console.error("File `unsupported-browser.html` was not found. Check the module configuration.");
      return false;
    }
    return unsupportedBrowser;
  }
}
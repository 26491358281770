import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default (deliveryTimesPerProductEnabled = true) => Query.named('fastestDeliveries')
  .toDeliveryCalculator()
  .where('products', variable('products', '[ProductInput!]'))
  .where('deliveryMethods', variable('deliveryMethods', '[Int!]'))
  .where('includeReservations', variable('includeReservations', 'Boolean!', false))
  .fields([
    field('bundle', [
      field('deliveryMethod'),
      field('pickupPlaceType'),
      field('deliveryTime'),
    ]),
    ...deliveryTimesPerProductEnabled
      ? [field('separate', [
          field('sku'),
          field('methods', [
            field('deliveryMethod'),
            field('pickupPlaceType'),
            field('deliveryTime'),
          ]),
        ])]
      : [],
  ])

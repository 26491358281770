import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import sentryConfig from "#sentry-config";
var delayedCalls = [];
var SentryMock = {};
var sentryReadyResolve;
var loadInitiated = false;
var loadCompleted = false;
var delayedGlobalErrors = [];
var delayedUnhandledRejections = [];
var delayGlobalError = function delayGlobalError(event) {
  delayedGlobalErrors.push([event.message, event.filename, event.lineno, event.colno, event.error]);
};
var delayUnhandledRejection = function delayUnhandledRejection(event) {
  if ("reason" in event && event.reason) {
    event = event.reason;
  } else if ("detail" in event && event.detail && "reason" in event.detail && event.detail.reason) {
    event = event.detail.reason;
  }
  delayedUnhandledRejections.push(event);
};
var vueErrorHandler = Vue.config.errorHandler;
Vue.config.errorHandler = function (error, vm, info) {
  if (!loadCompleted) {
    if (vm) {
      vm.$sentry.captureException(error);
    }
    if (Vue.util) {
      Vue.util.warn("Error in ".concat(info, ": \"").concat(error.toString(), "\""), vm);
    }
    console.error(error);
  }
  if (vueErrorHandler) {
    return vueErrorHandler(error, vm, info);
  }
};
var getApiMethods = function getApiMethods(packageApi) {
  var apiMethods = [];
  for (var key in packageApi) {
    if (key === "default") {
      for (var subKey in packageApi[key]) {
        if (typeof packageApi[key][subKey] === "function") {
          apiMethods.push(subKey);
        }
      }
      continue;
    }
    if (typeof packageApi[key] === "function") {
      apiMethods.push(key);
    }
  }
  return apiMethods;
};
var API_METHODS = getApiMethods(Sentry);
var addedIntegrations = [Sentry.extraErrorDataIntegration(), Sentry.reportingObserverIntegration()];
export default defineNuxtPlugin(function (nuxtApp) {
  API_METHODS.forEach(function (key) {
    SentryMock[key] = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return delayedCalls.push([key, args]);
    };
  });
  window.addEventListener("error", delayGlobalError);
  window.addEventListener("unhandledrejection", delayUnhandledRejection);
  var loadSentryHook = function loadSentryHook() {
    return attemptLoadSentry(nuxtApp);
  };
  var sentryReadyPromise = new Promise(function (resolve) {
    sentryReadyResolve = resolve;
  });
  var sentryReady = function sentryReady() {
    return sentryReadyPromise;
  };
  return {
    provide: {
      sentryReady: sentryReady,
      sentryLoad: loadSentryHook,
      Sentry: SentryMock
    }
  };
});
var attemptLoadSentry = function attemptLoadSentry(nuxtApp) {
  if (loadInitiated) {
    return;
  }
  loadInitiated = true;
  if (!window.$nuxt) {
    console.warn("$sentryLoad was called but window.$nuxt is not available, delaying sentry loading until onNuxtReady callback. Do you really need to use lazy loading for Sentry?");
    window.onNuxtReady(function () {
      return loadSentry(nuxtApp);
    });
    return;
  }
  loadSentry(nuxtApp);
};
var loadSentry = function loadSentry(nuxtApp) {
  if (loadCompleted) {
    return;
  }
  var dsn = sentryConfig.dsn,
    releaseName = sentryConfig.releaseName;
  Sentry.init({
    Vue: Vue,
    dsn: dsn,
    release: releaseName,
    integrations: function integrations(defaultInegrations) {
      return [].concat(_toConsumableArray(defaultInegrations), addedIntegrations);
    }
  });
  loadCompleted = true;
  window.removeEventListener("error", delayGlobalError);
  window.removeEventListener("unhandledrejection", delayUnhandledRejection);
  if (delayedGlobalErrors.length) {
    if (window.onerror) {
      console.info("Reposting global errors after Sentry has loaded");
      for (var _i = 0, _delayedGlobalErrors = delayedGlobalErrors; _i < _delayedGlobalErrors.length; _i++) {
        var errorArgs = _delayedGlobalErrors[_i];
        window.onerror.apply(window, errorArgs);
      }
    }
    delayedGlobalErrors = [];
  }
  if (delayedUnhandledRejections.length) {
    if (window.onunhandledrejection) {
      console.info("Reposting unhandled promise rejection errors after Sentry has loaded");
      for (var _i2 = 0, _delayedUnhandledReje = delayedUnhandledRejections; _i2 < _delayedUnhandledReje.length; _i2++) {
        var reason = _delayedUnhandledReje[_i2];
        window.onunhandledrejection(reason);
      }
    }
    delayedUnhandledRejections = [];
  }
  delayedCalls.forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      methodName = _ref2[0],
      args = _ref2[1];
    return Sentry[methodName].apply(Sentry, args);
  });
  forceInject(nuxtApp, "Sentry", Sentry);
  sentryReadyResolve(Sentry);
  API_METHODS.forEach(function (key) {
    SentryMock[key] = function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      return Sentry[key].apply(Sentry, args);
    };
  });
  var _useRuntimeConfig = useRuntimeConfig(),
    environmentName = _useRuntimeConfig.public.environmentName;
  if (environmentName !== "local") {
    delayedCalls = void 0;
    SentryMock = void 0;
  } else {
    delayedCalls = [];
  }
  sentryReadyResolve = void 0;
};
function forceInject(nuxtApp, key, value) {
  var injectKey = "$".concat(key);
  nuxtApp[injectKey] = value;
  window.$nuxt.$options[injectKey] = value;
}
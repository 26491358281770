/* eslint-disable no-param-reassign */
import {
  getDefaultShippingAddress,
  getDefaultShippingMeta,
  getDefaultMarketplaceShippingMeta,
} from '../utils/addressHelpers.js'
import { setStateByPath } from '../utils/StoreUtils.js'
import { INVALID } from '../utils/DataUtils.js'

export const state = () => ({
  address: getDefaultShippingAddress(),
  marketplaceAddress: getDefaultShippingAddress(),
  availableQuantityInStock: null,
  dataFetchStatus: {
    countOfPharmacies: INVALID,
  },
  meta: getDefaultShippingMeta(),
  marketplaceMeta: getDefaultMarketplaceShippingMeta(),
  modalType: null,
  openClickCollectModal: false,
  savingPostcode: false,
  savingShippingAddress: false,
  shippingAddressEntitySaved: false,
  marketplaceShippingAddressEntitySaved: false,
  totalAvailableQtyInStock: null,
})

export const mutations = {
  setShippingStateGeneric: setStateByPath,

  resetShippingState: (moduleState) => {
    moduleState.address = getDefaultShippingAddress()
    moduleState.meta = getDefaultShippingMeta()
    moduleState.shippingAddressEntitySaved = false
  },

  resetShippingAddress: (moduleState) => {
    moduleState.address = getDefaultShippingAddress()
  },
}

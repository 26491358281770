import { getPlacementBanners } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

export default async function (pageType = null) {
  const userId = ''
  const config = this?.config ?? {
    limit: 1,
    pageType,
  }
  return await getPlacementBanners('search-suggest-banner', userId, config, this?.ctx)
}

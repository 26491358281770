/* eslint-disable complexity, camelcase */
import { mapDataToModel, withDefault } from '@nsf/core/Mapper.js'
import useAppConfig from '@nsf/use/composables/useAppConfig.js'
import { mergeWithoutDuplicates } from '@nsf/utils/ArrayUtils.js'
import { isNullish } from '@nsf/core/Utils.js'
import {
  getDefaultAddress, getDefaultShippingAddress, getDefaultCompany, getDefaultContact,
} from '../utils/addressHelpers.js'
import mapDataToBillingAddressContact from './BillingAddressContactMapper.js'
import mapDataToBillingAddressCompany from './BillingAddressCompanyMapper.js'
import { mapDataToBillingAddress } from './BillingAddressMapper.js'
import { mapDataToShippingMethod, mapDataToShippingMethods } from './ShippingMethodMapper.js'
import mapDataToShippingAddress from './ShippingAddressMapper.js'
import { getDefaultPrices } from '../repositories/CartRepositoryDefaults.js'
import {
  getDefaultAppliedRestriction,
  getDefaultShippingMethod,
} from '../repositories/ShippingMethodRepositoryDefaults.js'
import { getDefaultPaymentMethod } from '../repositories/PaymentMethodRepositoryDefaults.js'
import { mapDataToCartDetails } from './CartItemsMapper.js'
import { COMMON_MAP_METHOD_CODE } from '../utils/StoreUtils.js'
import { mapCartDataToSalesRules } from './SalesRulesMapper.js'
import mapSellersPrices from './SellersPricesMapper.js'

const {
  checkout: {
    carrierCodes: {
      clickAndCollect: {
        pickupPlaceCarrierCode,
      },
    },

    features: {
      useCommonMap,
    },
  },
} = useAppConfig()

export const withCommonMapProxyShippingMethod = (shippingMethods, appliedShippingRestrictions = []) => {
  const isPickupPlaceShippingMethod = ({ carrierCode }) => carrierCode === pickupPlaceCarrierCode
  const pickupPlaceShippingMethods = shippingMethods.filter(isPickupPlaceShippingMethod)

  const isSomePickupPlaceRestricted = appliedShippingRestrictions.some(
    ({ code }) => code.includes(pickupPlaceCarrierCode),
  )

  if ((pickupPlaceShippingMethods.length > 0 && isSomePickupPlaceRestricted) || pickupPlaceShippingMethods.length > 1) {
    const remainingShippingMethods = shippingMethods.filter((it) => !isPickupPlaceShippingMethod(it))
    const pickupPlaceShippingMethodIndex = shippingMethods.findIndex(isPickupPlaceShippingMethod)

    const pickupPlaceShippingMethod = {
      carrierCode: pickupPlaceCarrierCode,
      icon: '/img/delivery/common-map.svg',
      methodCode: COMMON_MAP_METHOD_CODE,
      methodTitle: pickupPlaceShippingMethods[0].carrierTitle, // this is intentional
      integrationId: mergeWithoutDuplicates(...pickupPlaceShippingMethods.map(({ integrationId }) => integrationId)),
      pickupPlaceTypes: mergeWithoutDuplicates(
        ...pickupPlaceShippingMethods.map(({ pickupPlaceTypes }) => pickupPlaceTypes),
      ),
      priceInclTax: {
        minValue: Math.min(...pickupPlaceShippingMethods.map(({ priceInclTax }) => priceInclTax?.value)),
        maxValue: Math.max(...pickupPlaceShippingMethods.map(({ priceInclTax }) => priceInclTax?.value)),
        currency: pickupPlaceShippingMethods[0].priceInclTax?.currency,
      },
      pickupPlaceShippingMethods,
    }

    return remainingShippingMethods.toSpliced(pickupPlaceShippingMethodIndex, 0, pickupPlaceShippingMethod)
  }

  return shippingMethods
}

export const mapDataToSimpleCart = (response) => {
  const { cart, shipping_addresses } = response

  const {
    available_shipping_methods,
    is_common_map_enabled,
  } = shipping_addresses?.[0] ?? {}

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )

  const isCommonMapEnabled = is_common_map_enabled || useCommonMap

  if (isCommonMapEnabled) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods)
  }

  const messages = cart.messages ?? []

  return {
    success: true,
    ...mapDataToCartDetails({ cart }),
    shippingMethods,
    messages,
  }
}

export const mapSelectedShippingMethod = (selected_shipping_method) => {
  if (!selected_shipping_method) {
    return null
  }
  return mapDataToModel(selected_shipping_method)
}

export const mapDefaultDataToSimpleCart = () => ({
  success: false,
  prices: getDefaultPrices(),
  itemGroups: [],
  appliedCoupons: [],
  appliedGiftCards: [],
  loyaltyProgram: {},
  freeShipping: {},
  isReservationAllowed: true,
})

export const mapDataToCart = (cartData) => {
  const {
    email,
    billing_address,
    shipping_addresses,
    available_payment_methods,
    applied_payment_restrictions,
    selected_payment_method,
    order_type,
    errors,
    ...cart
  } = cartData

  const {
    available_shipping_methods,
    applied_shipping_restrictions,
    selected_shipping_method,
    is_common_map_enabled,
    ...rest
  } = shipping_addresses?.[0] ?? {}

  const shippingMethodOnCart = mapSelectedShippingMethod(selected_shipping_method) ?? {}
  const { carrierCode, methodCode, pickupPlaceId } = shippingMethodOnCart

  const { code = '' } = selected_payment_method ?? {}

  const marketplaceCarrierCode = cartData.marketplace_quote?.marketplace_quote_address?.selected_shipping_method?.carrier_code ?? ''
  const marketplaceMethodCode = cartData.marketplace_quote?.marketplace_quote_address?.selected_shipping_method?.method_code ?? ''
  const {
    selected_shipping_method: marketplace_selected_shipping_method,
  } = cartData.marketplace_quote?.marketplace_quote_address ?? {}

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )

  const appliedShippingRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_shipping_restrictions ?? []),
  )

  const isCommonMapEnabled = is_common_map_enabled || useCommonMap

  if (isCommonMapEnabled) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods, appliedShippingRestrictions)
  }

  let selectedShippingMethod = carrierCode && methodCode ? `${carrierCode}~${methodCode}` : ''
  const commonMapShippingMethod = shippingMethods.find(
    (shippingMethod) => shippingMethod.methodCode === COMMON_MAP_METHOD_CODE,
  )
  if (commonMapShippingMethod && carrierCode === commonMapShippingMethod.carrierCode) {
    selectedShippingMethod = `${commonMapShippingMethod.carrierCode}~${commonMapShippingMethod.methodCode}`
  }

  const paymentMethods = withDefault(
    getDefaultPaymentMethod,
    mapDataToModel(available_payment_methods ?? []),
  )

  const appliedPaymentRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_payment_restrictions ?? []),
  )

  const marketplacePaymentMethods = withDefault(
    getDefaultPaymentMethod,
    mapDataToModel(cartData.marketplace_quote?.marketplace_quote_address?.available_payment_methods ?? []),
  )

  const marketplaceSelectedPaymentMethod = cartData.marketplace_quote?.marketplace_payment_method_code ?? ''

  const marketplaceShippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(cartData.marketplace_quote?.marketplace_quote_address?.available_shipping_methods ?? []),
  )

  const marketplaceSelectedShippingMethod = marketplaceCarrierCode && marketplaceMethodCode ? `${marketplaceCarrierCode}~${marketplaceMethodCode}` : ''

  const sellersPrices = mapSellersPrices(cartData.marketplace_quote, cart.drmax_quote_type)

  const messages = cartData.messages ?? []

  return {
    appliedPaymentRestrictions,
    appliedShippingRestrictions,
    billingAddress: withDefault(
      getDefaultAddress,
      mapDataToBillingAddress(billing_address ?? {}),
    ),
    billingCompany: withDefault(
      getDefaultCompany,
      mapDataToBillingAddressCompany(billing_address ?? {}),
    ),
    billingContact: withDefault(
      getDefaultContact,
      mapDataToBillingAddressContact(billing_address ?? {}),
    ),
    email: email ?? '',
    features: cartData.features ? mapDataToModel(cartData.features) : null,
    isCommonMapEnabled,
    marketplacePaymentMethods,
    marketplaceSelectedPaymentMethod,
    marketplaceSelectedShippingMethod,
    marketplaceShippingAddress: withDefault(
      getDefaultAddress,
      mapDataToShippingAddress(cartData.marketplace_quote?.marketplace_quote_address ?? {}),
    ),
    marketplaceShippingMethodOnCart: marketplace_selected_shipping_method
      ? mapDataToModel(marketplace_selected_shipping_method)
      : null,
    marketplaceShippingMethods,
    messages,
    orderType: order_type,
    paymentMethods,
    pickupPlaceId,
    selectedPaymentMethod: code,
    selectedShippingMethod,
    shippingAddress: withDefault(
      getDefaultAddress,
      mapDataToShippingAddress(rest ?? {}),
    ),
    shippingMethod: withDefault(
      getDefaultShippingMethod,
      mapDataToShippingMethod(available_shipping_methods[0] ?? {}),
    ),
    shippingMethodOnCart,
    shippingMethods,
    success: true,
    ...mapDataToCartDetails({
      errors,
      cart,
    }),
    sellersPrices,
  }
}

export const mapDefaultDataToCart = () => ({
  appliedCoupons: [],
  billingAddress: getDefaultAddress(),
  billingCompany: getDefaultCompany(),
  billingContact: getDefaultContact(),
  email: '',
  freeShipping: {},
  isReservationAllowed: true,
  itemGroups: [],
  loyaltyProgram: {},
  paymentMethods: [],
  pickupPlaceId: '',
  prices: getDefaultPrices(),
  selectedPaymentMethod: '',
  selectedShippingMethod: '',
  shippingAddress: getDefaultShippingAddress(),
  shippingMethodOnCart: null,
  shippingMethods: [],
  success: false,
})

export const mapShippingAndPaymentMethods = (cart) => {
  const {
    available_shipping_methods = [],
    applied_shipping_restrictions = [],
    selected_shipping_method = {},
    is_common_map_enabled = false,
  } = cart?.shipping_addresses?.[0] ?? {}
  const {
    available_payment_methods = [],
    applied_payment_restrictions = [],
    selected_payment_method = {},
    order_type,
  } = cart ?? {}

  const shippingMethodOnCart = mapSelectedShippingMethod(selected_shipping_method) ?? {}
  const { carrierCode, methodCode, pickupPlaceId } = shippingMethodOnCart

  const { code = '' } = selected_payment_method ?? {}

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )
  const appliedShippingRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_shipping_restrictions),
  )

  const isCommonMapEnabled = is_common_map_enabled || useCommonMap

  if (isCommonMapEnabled) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods, appliedShippingRestrictions)
  }

  let selectedShippingMethod = carrierCode && methodCode ? `${carrierCode}~${methodCode}` : ''
  const commonMapShippingMethod = shippingMethods.find(
    (shippingMethod) => shippingMethod.methodCode === COMMON_MAP_METHOD_CODE,
  )
  if (commonMapShippingMethod && carrierCode === commonMapShippingMethod.carrierCode) {
    selectedShippingMethod = `${commonMapShippingMethod.carrierCode}~${commonMapShippingMethod.methodCode}`
  }

  const {
    carrier_code: marketplaceCarrierCode,
    method_code: marketplaceMethodCode,
  } = cart.marketplace_quote?.marketplace_quote_address?.selected_shipping_method ?? {}

  const marketplaceSelectedShippingMethod = marketplaceCarrierCode && marketplaceMethodCode ? `${marketplaceCarrierCode}~${marketplaceMethodCode}` : ''
  const marketplaceSelectedPaymentMethod = cart.marketplace_quote?.marketplace_payment_method_code ?? ''

  return {
    appliedPaymentRestrictions: withDefault(
      getDefaultAppliedRestriction,
      mapDataToModel(applied_payment_restrictions),
    ),
    appliedShippingRestrictions,
    marketplaceSelectedPaymentMethod,
    marketplaceSelectedShippingMethod,
    orderType: order_type,
    paymentMethods: withDefault(
      getDefaultPaymentMethod,
      mapDataToModel(available_payment_methods),
    ),
    selectedPaymentMethod: code,
    selectedPickupPlaceId: pickupPlaceId,
    selectedShippingMethod,
    shippingMethodOnCart,
    shippingMethods,
  }
}

export const mapSetPaymentMethodOnCartResponse = (cart) => {
  const { code = '' } = cart.selected_payment_method ?? {}

  const {
    available_shipping_methods = [],
    applied_shipping_restrictions = [],
    is_common_map_enabled,
  } = cart.shipping_addresses?.[0] ?? {}

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )
  const appliedShippingRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_shipping_restrictions),
  )

  const isCommonMapEnabled = is_common_map_enabled || useCommonMap

  if (isCommonMapEnabled) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods, appliedShippingRestrictions)
  }

  const {
    itemGroups, freeShipping,
  } = mapCartDataToSalesRules(cart)

  return {
    appliedShippingRestrictions,
    firstPartyMarketplacePrices: withDefault(
      getDefaultPrices,
      mapDataToModel(cart.marketplace_quote?.marketplace_quote_totals?.one_p_quote_prices ?? {}),
    ),
    freeShipping,
    itemGroups,
    offlineTotal: cart.marketplace_quote?.marketplace_quote_totals?.payment_summary?.offline_total?.value ?? 0,
    onlineTotal: cart.marketplace_quote?.marketplace_quote_totals?.payment_summary?.online_total?.value ?? 0,
    prices: withDefault(
      getDefaultPrices,
      mapDataToModel(cart.prices),
    ),
    selectedPaymentMethod: code,
    sellersPrices: mapSellersPrices(cart.marketplace_quote, cart.drmax_quote_type),
    shippingMethods,
  }
}

const shouldResetShippingMethod = (shippingMethod, mpQuote, isOnlyMarketplaceCart, has3rdPartyProduct) => {
  const { marketplace_quote_address: mpAddress } = mpQuote ?? {}

  const mpShippingMethodMissing = isNullish(mpAddress?.selected_shipping_method.carrier_code ?? null)
    && isNullish(mpAddress?.selected_shipping_method?.method_code ?? null)
  const shippingMethodMissing = isNullish(shippingMethod?.carrier_code ?? null)
    && isNullish(shippingMethod?.method_code ?? null)

  if (isOnlyMarketplaceCart) {
    return mpShippingMethodMissing
  } if (has3rdPartyProduct) {
    return mpShippingMethodMissing || shippingMethodMissing
  }

  return shippingMethodMissing
}

const shouldResetPaymentMethod = (paymentMethod, mpQuote, isOnlyMarketplaceCart, has3rdPartyProduct) => {
  const { marketplace_payment_method_code: paymentMethodCode } = mpQuote ?? {}

  const mpPaymentMethodMissing = isNullish(paymentMethodCode ?? null)
  const paymentMethodMissing = isNullish(paymentMethod ?? null)

  if (isOnlyMarketplaceCart) {
    return mpPaymentMethodMissing
  } if (has3rdPartyProduct) {
    return mpPaymentMethodMissing || paymentMethodMissing
  }

  return paymentMethodMissing
}

const shouldResetShippingAndPayment = (cart) => {
  const { marketplace_quote, selected_payment_method, drmax_quote_type } = cart

  const {
    selected_shipping_method,
  } = cart.shipping_addresses[0]

  const isOnlyMarketplaceCart = drmax_quote_type === 'marketplace'
  const has3rdPartyProduct = drmax_quote_type === 'mixed'

  // eslint-disable-next-line max-len
  const resetShippingMethod = shouldResetShippingMethod(selected_shipping_method, marketplace_quote, isOnlyMarketplaceCart, has3rdPartyProduct)
  // eslint-disable-next-line max-len
  const resetPaymentMethod = shouldResetPaymentMethod(selected_payment_method, marketplace_quote, isOnlyMarketplaceCart, has3rdPartyProduct)

  return {
    resetPaymentMethod, resetShippingMethod,
  }
}

export const mapSetShippingAddressOnCartResponse = (cart) => {
  const { applied_payment_restrictions } = cart

  const {
    available_shipping_methods,
    selected_shipping_method,
    applied_shipping_restrictions,
    is_common_map_enabled,
  } = cart.shipping_addresses?.[0] ?? {}

  const shippingMethodOnCart = mapSelectedShippingMethod(selected_shipping_method) ?? {}
  const { carrierCode, methodCode } = shippingMethodOnCart

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )
  const appliedShippingRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_shipping_restrictions),
  )

  const isCommonMapEnabled = is_common_map_enabled || useCommonMap

  if (isCommonMapEnabled) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods, appliedShippingRestrictions)
  }

  let selectedShippingMethod = carrierCode && methodCode ? `${carrierCode}~${methodCode}` : ''
  const commonMapShippingMethod = shippingMethods.find(
    (shippingMethod) => shippingMethod.methodCode === COMMON_MAP_METHOD_CODE,
  )
  if (commonMapShippingMethod && carrierCode === commonMapShippingMethod.carrierCode) {
    selectedShippingMethod = `${commonMapShippingMethod.carrierCode}~${commonMapShippingMethod.methodCode}`
  }

  const { resetPaymentMethod, resetShippingMethod } = shouldResetShippingAndPayment(cart)

  return {
    marketplaceShippingAddress: withDefault(
      getDefaultAddress,
      mapDataToShippingAddress(cart.marketplace_quote?.marketplace_quote_address ?? {}),
    ),
    selectedShippingMethod,
    shippingAddress: mapDataToShippingAddress(cart.shipping_addresses[0]),
    shippingMethods,
    appliedShippingRestrictions,
    resetShippingMethod,
    paymentMethods: withDefault(
      getDefaultPaymentMethod,
      mapDataToModel(cart.available_payment_methods),
    ),
    appliedPaymentRestrictions: withDefault(
      getDefaultAppliedRestriction,
      mapDataToModel(applied_payment_restrictions),
    ),
    resetPaymentMethod,
  }
}

export const mapSetBillingAddressOnCartResponse = (cart) => {
  const { applied_payment_restrictions } = cart

  const {
    available_shipping_methods,
    applied_shipping_restrictions,
    is_common_map_enabled,
  } = cart.shipping_addresses[0]

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethods(available_shipping_methods ?? []),
  )
  const appliedShippingRestrictions = withDefault(
    getDefaultAppliedRestriction,
    mapDataToModel(applied_shipping_restrictions),
  )

  if (is_common_map_enabled || useCommonMap) {
    shippingMethods = withCommonMapProxyShippingMethod(shippingMethods, appliedShippingRestrictions)
  }

  const { resetPaymentMethod, resetShippingMethod } = shouldResetShippingAndPayment(cart)

  return {
    billingContact: withDefault(
      getDefaultContact,
      mapDataToBillingAddressContact(cart.billing_address),
    ),
    billingCompany: withDefault(
      getDefaultCompany,
      mapDataToBillingAddressCompany(cart.billing_address),
    ),
    billingAddress: withDefault(
      getDefaultAddress,
      mapDataToBillingAddress(cart.billing_address),
    ),
    shippingMethods,
    appliedShippingRestrictions,
    resetShippingMethod,
    paymentMethods: withDefault(
      getDefaultPaymentMethod,
      mapDataToModel(cart.available_payment_methods),
    ),
    appliedPaymentRestrictions: withDefault(
      getDefaultAppliedRestriction,
      mapDataToModel(applied_payment_restrictions),
    ),
    resetPaymentMethod,
  }
}

/* eslint-disable */

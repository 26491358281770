/* eslint-disable sort-keys */
/* eslint-disable no-shadow */
import { remove } from '@nsf/core/Storage.js'
import { setState } from '@nsf/core/Store.js'
import pushEventLoginError from '@nsf/gtm/events/loginError.js'
import { pushUserEvent } from '@nsf/gtm/events/user.js'
import { pushEventUserData } from '@nsf/gtm/events/userData.js'
import { pushEventUserLogin } from '@nsf/gtm/events/userLogin.js'
import pushEventUserLogout from '@nsf/gtm/events/userLogout.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { initRecaptcha } from '@nsf/my-account/utils/initRecaptcha.js'
import {
  isMyAccountEnabled,
  hasAnyToken,
  removeTokens,
  getTokenFromReqCookies,
  getAuthorization,
} from '../utils/auth.js'
import {
  getCurrentUser,
  getDefaultUser,
} from '../repositories/UserRepository.js'
import { login } from '../repositories/authRepository.js'
import { transformUserEmailAndPush, pushEventUserDataFn } from '../utils/index.js'
import { getLoginError, isUnauthorizedStatus } from '../utils/serverErrorStatus.js'
import logger from '../logger.js'

const {
  myAccount: {
    features: {
      myAccountSorpEnabled,
    },
  },
} = useAppConfig()

export const state = () => ({
  loginError: null,
})

export const mutations = {
  setState,
}

export const actions = {
  async init({
    commit, state, dispatch, rootState,
  }) {
    if (!isMyAccountEnabled || !rootState['_base/config'].config.drmaxNsfMyAccountEnable) {
      removeTokens()
      commit('setState', { isInitialized: true }, { root: true })
      return {}
    }

    if (!rootState['my-account/user'].isLoggedIn && !rootState['my-account/user'].isLoggingIn && !rootState['my-account/user'].isLoggingOut) {
      commit('setState', { isLoggingIn: true, isInitialized: false }, { root: true })

      if (hasAnyToken()) {
        const { user, ok } = await getCurrentUser()

        // Initial push user event to populate local storage
        if (myAccountSorpEnabled) {
          pushUserEvent(user)
        }

        commit('my-account/user/setState', { isLoggedIn: ok }, { root: true })

        if (!ok) {
          commit('my-account/user/setState', { isLoggingIn: false }, { root: true })

          return { isLoggedIn: false }
        }

        dispatch('my-account/user/setUser', user, { root: true })
        pushEventUserDataFn(user)
      }

      dispatch('my-account/user/fetchUserActions', null, { root: true })

      initRecaptcha(this)
      commit('my-account/user/setState', { isLoggingIn: false, isInitialized: true }, { root: true })
    }

    return { isLoggedIn: state.isLoggedIn }
  },

  async login({
    commit, state, dispatch, rootState,
  }, payload) {
    if (!isMyAccountEnabled || !rootState['_base/config'].config.drmaxNsfMyAccountEnable) {
      removeTokens()
      commit('setState', { isInitialized: true }, { root: true })
    }

    commit('my-account/user/setState', { isLoggingIn: true, isInitialized: false }, { root: true })

    const { ok, status: loginStatus } = await login(payload)

    if (ok) {
      const { ok: userOk, status: getUserStatus, user } = await getCurrentUser()

      if (userOk) {
        commit('my-account/user/setState', { isLoggedIn: userOk }, { root: true })
        dispatch('my-account/user/setUser', user, { root: true })
        dispatch('my-account/user/fetchUserActions', null, { root: true })

        pushEventUserData(user)
        pushEventUserLogin(user)
      } else {
        commit('setState', { loginError: getLoginError(getUserStatus) })
      }
    } else {
      if (isUnauthorizedStatus(loginStatus)) {
        await transformUserEmailAndPush(
          payload.email,
          pushEventLoginError(),
          this,
          getLoginError(loginStatus),
        )
      }

      commit('setState', { loginError: getLoginError(loginStatus) })
    }

    commit('my-account/user/setState', { isLoggingIn: false, isInitialized: true }, { root: true })

    return { isLoggedIn: rootState['my-account/user'].isLoggedIn }
  },

  async logout({ commit, dispatch, state }) {
    commit('my-account/user/setState', { isLoggingOut: true, isInitialized: false }, { root: true })

    removeTokens()

    commit('my-account/user/setState', { isLoggedIn: false }, { root: true })
    dispatch('my-account/user/setUser', getDefaultUser(), { root: true })
    dispatch('my-account/user/removeUserActions', null, { root: true })

    commit('my-account/user/setState', { isLoggingOut: false }, { root: true })

    pushEventUserDataFn(state.user, 'Y')
    pushEventUserLogout()
    pushEventUserData()
    remove('user/event')

    commit('my-account/user/setState', { isInitialized: true }, { root: true })
  },

  resetLoginError({ state }) {
    state.loginError = null
  },
}

export const nuxtServerInit = async ({
  commit, dispatch, rootState,
}, { req }) => {
  return
  if (!isMyAccountEnabled || !rootState['_base/config'].config.drmaxNsfMyAccountEnable) {
    dispatch('my-account/user/fetchUserActions', null, { root: true })
    commit('my-account/user/setState', { isInitialized: true }, { root: true })
    return
  }

  if (!rootState['my-account/user'].isLoggedIn && !rootState['my-account/user'].isLoggingIn && !rootState['my-account/user'].isLoggingOut) {
    const token = getTokenFromReqCookies(req)

    if (!token) {
      return
    }

    const headers = token ? { Authorization: getAuthorization(token) } : {}

    const { user, ok } = await getCurrentUser(headers)

    if (ok) {
      commit('my-account/user/setState', { isLoggedIn: true, isInitialized: true }, { root: true })
      dispatch('my-account/user/setUser', user, { root: true })

      dispatch('my-account/user/fetchUserActions', headers, { root: true })
    } else {
      logger.error('Error while fetching user data from token')
    }

    commit('my-account/user/setState', { isInitialized: true }, { root: true })
  }
}

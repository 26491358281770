export const persooLoaderLabel = 'Persoo'
export const magentoLoaderLabel = 'Magento'

export default {
  props: {
    /**
     * Identifier of the product the loaded products relates to. It's used when the loaders property is being processed.
     */
    identifier: {
      type: [Number, Array],
      default: null,
    },
    /**
     * Loader array to be used when loading products. For more information see {@link this#strategy}
     */
    productsLoaders: {
      type: Array,
      default: () => [],
    },
    preloadedProducts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      productsLoaded: [...this.preloadedProducts],
    }
  },

  mounted() {
    this.loadProducts()
  },

  methods: {
    async loadProducts() {
      let productsLoaded = [...this.productsLoaded]
      for (const loader of this.productsLoaders) {
        const { products } = await loader(this.identifier)

        productsLoaded = productsLoaded.concat(products)
      }
      this.productsLoaded = productsLoaded

      this.$emit(
        'loaded',
        this.productsLoaded,
      )
    },
  },
}

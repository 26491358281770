import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    magentoUrl,
  },
} = useRuntimeConfig()

/* eslint-disable camelcase */
export const mapDataToBanner = (data, translations = {}, recursive = false) => {
  const banners = mapDataToModel(data, {
    image: ['imagePath', (image) => `${magentoUrl}/media/drmax/action_banners/${image}`],
    imageDesktopUrl: 'imagePath',
    image_mobile: ['imageMobilePath', (image) => `${magentoUrl}/media/drmax/action_banners/${image}`],
    imageMobileUrl: 'imageMobilePath',
    desc: 'description',
    title: 'name',
    url: 'urlLink',
    ...translations,
  }, recursive)

  return banners.map((banner, index) => ({ ...banner, position: index + 1 }))
}

export const mapGQLDataToBanner = (data, translations = {}) => {
  const banners = mapDataToModel(data, {
    image_name: 'imagePath',
    image_name_mobile: 'imageMobilePath',
    ...translations,
  })

  return banners.map((banner, index) => ({ ...banner, position: index + 1 }))
}

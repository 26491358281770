import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useCountryFlags } from '@nsf/use/composables/useCountryFlags.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { toBoolean, toNumber, toString } from '../utils/index.js'
import { withRegionCodesPatched } from './RegionCodeMapper.js'
import { mapAgreements, mapAgreementsMyAccount } from './AgreementsMapper.js'

const {
  public: {
    myAccountSorpEnabled,
  },
} = useRuntimeConfig()

const {
  myAccount: {
    features: {
      companyItInvoicingEnabled,
      loyaltyCardV2,
      phoneCodeEnabled,
    },
  },
  rootConfig: {
    global: {
      phoneCodeSentSeparatelyEnabled,
      otcFormEnabled,
      skDicEnabled,
      splitStreetNumberBeforeSavingEnabled,
      streetNumberEnabled,
      phoneCode,
      phoneCodeSelectEnabled,
    },
  },
} = useAppConfig()

const { isCZ, isRO } = useCountryFlags()

// eslint-disable-next-line complexity
export const mapDataToUser = async (data, translations = {}) => {
  const user = mapDataToModel(data, {
    clientId: Number,
    name: ['firstName', String],
    surname: ['lastName', String],
    login: ['loginEmail', String],
    email: ['correspondenceEmail', String],
    mobileNumber: ['phoneNumber', String],
    mobilePrefix: ['phoneCode', String],
    birthDate: String,
    sex: String,
    addresses: (x) => mapDataToModel(x, {
      street: String,
      streetNumber: String,
      city: String,
      regionCode: String,
      postalCode: ['zip', String],
      country: String,
      company: ['companyName', String],
      identCode: ['companyRegistrationNumber', String],
      vatCode: ['vatId', String],
      phoneNumber: String,
    }),
    registrationCode: String,
    agreements: (x) => mapDataToModel(x, {
      channel: String,
      value: String,
    }),
    additionalParams: (x) => mapDataToModel(x, {
      pregnant: ['pregnant', Boolean],
      breast_feeding: ['breast_feeding', Boolean],
      weight: ['weight', String],
      height: ['height', String],
      allergies: ['allergies', String],
      treatments: ['treatments', String],
      drmax_sdi: ['sdi', String],
      drmax_pec: ['pec', String],
      ...(skDicEnabled && { drmax_sk_dic: ['skDic', String] }),
    }),
    ...translations,
  })

  if (splitStreetNumberBeforeSavingEnabled && !streetNumberEnabled) {
    user.addresses = user.addresses.map((val) => {
      if (val.street && val.streetNumber) {
        val.street = `${val.street} ${val.streetNumber}`
      }
      return val
    })
  }

  if (user.phoneNumber && phoneCodeEnabled) {
    // CZ, PL, SK
    if (phoneCodeSentSeparatelyEnabled) {
      // CZ, PL
      if (user.phoneCode?.length > 0 && !user.phoneCode.startsWith('+')) {
        // CZ
        user.phoneCode = `+${user.phoneCode}`
      }
      if (!phoneCodeSelectEnabled) {
        user.phoneCode = phoneCode
      }
    } else {
      // SK, IT
      const index = phoneCode.length
      if (index) {
        user.phoneCode = !phoneCodeSelectEnabled
          ? phoneCode
          : user.phoneNumber.substring(0, index)
        user.phoneNumber = user.phoneNumber.substring(index)
      }
    }
  }

  user.email = user.correspondenceEmail
  if (isRO) {
    user.addresses = await withRegionCodesPatched(user.addresses)
  }
  user.billingAddress = user.addresses.find(
    ({ addressType }) => addressType === 'BILLA',
  )
  if (user.billingAddress) {
    user.billingAddress.fiscalCode = user.fiscalCode ?? ''
    user.billingAddress.sdi = user.additionalParams.sdi || ''
    user.billingAddress.pec = user.additionalParams.pec || ''
    user.billingAddress.skDic = user.additionalParams.skDic || ''
  }
  user.registrationAddress = user.addresses.find(
    ({ addressType }) => addressType === 'REGA',
  )

  user.deliveryAddress = user.addresses.find(
    ({ addressType }) => addressType === 'DELIVA',
  )

  if (isCZ) {
    user.addressesMatch = !!user.deliveryAddress
      && !!user.registrationAddress
      && Object.keys(user.deliveryAddress)
        .filter((key) => key !== 'addressType')
        .every(
          (key) => user.deliveryAddress[key] === user.registrationAddress[key],
        )
  } else {
    user.addressesMatch = !!user.deliveryAddress
      && !!user.billingAddress
      && Object.keys(user.deliveryAddress)
        .filter((key) => key !== 'addressType')
        .every((key) => user.deliveryAddress[key] === user.billingAddress[key])
  }

  if (myAccountSorpEnabled) {
    user.accountType = data?.accountType
    user.terminateDate = data?.terminateDate || false
    user.cardCode = data.cardCode ?? null
  }

  user.ppdAgreement = user.agreements.find(({ channel }) => channel === 'PPD')?.value === 'Y'
    || false
  user.emailSmsAgreement = user.agreements.find(
    ({ channel }) => channel === 'EMAIL' || channel === 'SMS',
  )?.value === 'Y' || false
  user.thirdPartyMarketingAgreement = user.agreements.find(({ channel }) => channel === '3RD')?.value === 'Y'
    || false
  user.eblokAgreement = user.agreements.find(({ channel }) => channel === 'EBLOK')?.value === 'Y'
    || false

  user.rawAdditionalParams = data.additionalParams

  return user
}

export const mapOtcAdditionalParams = ({
  pregnant,
  breastFeeding,
  weight,
  height,
  allergies,
  treatments,
}) => ({
  pregnant: toBoolean(pregnant),
  breast_feeding: toBoolean(breastFeeding),
  weight: toNumber(weight),
  height: toNumber(height),
  allergies: toString(allergies),
  treatments: toString(treatments),
})

// eslint-disable-next-line complexity
export const mapUserToData = (data) => {
  const newData = JSON.parse(JSON.stringify(data))

  newData.addresses = []
  newData.fiscalCode = newData?.billingAddress?.fiscalCode ?? null

  if (splitStreetNumberBeforeSavingEnabled && !streetNumberEnabled) {
    splitStreetAndStreetNumber(newData.billingAddress)
    splitStreetAndStreetNumber(newData.registrationAddress)
    splitStreetAndStreetNumber(newData.deliveryAddress)
  }

  if (newData.registrationAddress) {
    newData.addresses.push(
      {
        ...newData.billingAddress,
        addressType: 'BILLA',
      },
      {
        ...newData.registrationAddress,
        addressType: 'REGA',
      },
    )

    if (!newData.deliveryAddress) {
      newData.addresses.push({
        ...newData.billingAddress,
        addressType: 'DELIVA',
      })
    }

    delete newData.billingAddress
    delete newData.registrationAddress
  } else if (newData.billingAddress) {
    newData.addresses.push({
      ...newData.billingAddress,
      addressType: 'BILLA',
    })

    newData.addresses.push({
      ...newData.billingAddress,
      addressType: 'REGA',
    })

    if (!newData.deliveryAddress) {
      newData.addresses.push({
        ...newData.billingAddress,
        addressType: 'DELIVA',
      })
    }

    delete newData.billingAddress
  }

  if (newData.deliveryAddress) {
    newData.addresses.push({
      ...newData.deliveryAddress,
      addressType: 'DELIVA',
    })
    delete newData.deliveryAddress
  }

  if (newData.addresses.length === 0) {
    delete newData.addresses
  }

  newData.additionalParams = {
    ...(otcFormEnabled
      && data.additionalParams
      && mapOtcAdditionalParams(data.additionalParams)),
    ...(skDicEnabled && {
      drmax_sk_dic: data.billingAddress?.skDic,
    }),
    ...(companyItInvoicingEnabled && {
      drmax_sdi: data.billingAddress?.sdi,
      drmax_pec: data.billingAddress?.pec,
      drmax_fiscal_code: newData.fiscalCode,
    }),
  }

  delete newData.rawAdditionalParams

  const model = {
    ...newData,
    clientId: newData.clientId,
    name: newData.firstName,
    surname: newData.lastName,
    login: newData.loginEmail,
    email: newData.correspondenceEmail,
    birthDate: newData.birthDate,
    sex: newData.sex,
    ...(loyaltyCardV2 ? { cardType: 'B' } : {}),
    addresses: newData.addresses?.map((address) => ({
      addressType: address.addressType,
      city: address.city,
      company: address.companyName || '', // PL dwh - null does not delete previous value
      country: address.country,
      identCode: address.companyRegistrationNumber,
      phoneNumber: address.phoneNumber,
      postalCode: mapZipCode(address.zip),
      regionCode: address.regionCode,
      street: address.street,
      streetNumber: address.streetNumber,
      vatCode: address.vatId || '', // PL dwh - null does not delete previous value
    })),
    agreements: [],
  }

  if (newData.ppdAgreement) {
    model.ppdAgreement = 'Y'
  }

  model.agreements = mapAgreementsMyAccount(newData)

  if (model.isBabyClub) {
    delete model.childStatus
    delete model.childSex
    delete model.isBabyClub
    delete model.childBirthDate
    delete model.childName
  }

  model.mobileNumber = phoneCodeEnabled && !phoneCodeSentSeparatelyEnabled && newData.phoneNumber
    ? newData.phoneCode + newData.phoneNumber // SK
    : newData.phoneNumber

  model.mobilePrefix = phoneCodeEnabled && phoneCodeSentSeparatelyEnabled && newData.phoneNumber
    ? newData.phoneCode // CZ, PL
    : null

  delete model.phoneNumber
  delete model.phoneCode

  if (!model.email) {
    model.email = model.login
  }

  if (!model.password) {
    delete model.email
    delete model.login
  }

  if (model?.registrationCode) {
    delete model.registrationCode
  }
  return model
}

export const mapAdvancedAgreements = (data) => {
  const agreements = JSON.parse(JSON.stringify(data))
  return agreements.map((el) => ({
    channel: el?.channel,
    topic: el?.topic,
    value: el?.value ? 'Y' : 'N',
    items: el?.items,
  }))
}

export const mapDataToRegistration = (data, translations = {}) => mapDataToModel(data, {
  clientId: Number,
  clientStatus: String,
  ppdAgreement: String,
  ...translations,
})

export const mapMissingPersonalDetailsToData = (data) => ({
  clientId: data.clientId,
  email: data.loginEmail || data.email,
  login: data.loginEmail || data.email,
  password: data.password,
  mobilePrefix: data.phoneCode,
  mobileNumber: data.phoneNumber,
  agreements: mapAgreements(data),
})

export const splitStreetAndStreetNumber = (address) => {
  if (address && !!address.street) {
    // Attempt to split the address at the last space where the following characters
    // could reasonably be part of a street number. This includes digits, letters,
    // and symbols like +, -, or /.
    const regex = /^(.*[^\d\s])(\s+)([\w\s\-/+]+)$/
    const matches = regex.exec(address.street)
    if (matches) {
      address.street = matches[1]
      address.streetNumber = matches[3]
    }
    return address
  }
}

export const mapZipCode = (zip) => {
  if (!zip) {
    return ''
  }
  const regex = /(^[0-9]{3})\s([0-9]{2})$/
  const matches = zip.match(regex)
  if (matches) {
    return `${matches[1]}${matches[2]}`
  }
  return zip
}

export const mapUserToApplicant = ({
  clientId,
  firstName,
  lastName,
  email,
  phoneNumber,
}) => ({
  clientId,
  name: firstName,
  surname: lastName,
  email,
  mobileNumber: phoneNumber,
})

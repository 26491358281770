import { setState } from "@nsf/core/Store.js";
export default {
  namespaced: true,
  state: function state() {
    return {
      confirmAgeModalVisible: false
    };
  },
  getters: {
    confirmAgeModalVisible: function confirmAgeModalVisible(state) {
      return state.confirmAgeModalVisible;
    }
  },
  mutations: {
    setState: setState
  },
  actions: {
    showConfirmAgeModal: function showConfirmAgeModal(_ref) {
      var commit = _ref.commit;
      commit("setState", {
        confirmAgeModalVisible: true
      });
    },
    hideConfirmAgeModal: function hideConfirmAgeModal(_ref2) {
      var commit = _ref2.commit;
      commit("setState", {
        confirmAgeModalVisible: false
      });
    },
    checkAdultsOnly: function checkAdultsOnly(_ref3) {
      var commit = _ref3.commit;
      if (!isAdultContentAllowed()) {
        commit("setState", {
          confirmAgeModalVisible: true
        });
      }
    },
    allowAdultContent: function allowAdultContent(_ref4) {
      var commit = _ref4.commit;
      setAdultsOnlyAllowed();
      commit("setState", {
        confirmAgeModalVisible: false
      });
    }
  }
};
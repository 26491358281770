/* eslint-disable no-param-reassign */
import { get, set } from '@nsf/core/Storage.js'
import { pushEventCartId } from '@nsf/gtm/events/cartId.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getDefaultAgreements, getRedirectAgreements } from '../utils/agreementHelpers.js'
import { setStateByPath, getItems, ORDER } from '../utils/StoreUtils.js'

const {
  base: {
    currency,
  },
  checkout: {
    carrierCodes: {
      clickAndCollect,
    },
    features: {
      otcDeliveryApproval: {
        enabled: otcDeliveryApprovalEnabled,
      } = {},
    },
  },
} = useAppConfig()

const {
  pharmacyCollectCarrierCode,
  pharmacyExpressCollectCarrierCode,
  packetaCollectCarrierCode,
  postOfficeCollectCarrierCode,
} = clickAndCollect

const forbiddenCarrierCodes = [pharmacyExpressCollectCarrierCode, postOfficeCollectCarrierCode]

const getDefaultCartState = () => ({
  cartId: '',
  eshopUrl: '',
  status: {
    arePharmacyStockStatusesInitialized: false,
    arePickupPlaceDeliveryDatesInitialized: false,
    arePacketaDeliveryDatesInitialized: false,
    generalWarnings: [],
    isAgreementsUpdating: false,
    isCartFetched: false,
    isCartUpdating: false,
    isCartWaiting: false,
    isCheckoutInitialized: false,
    isCheckoutUpdating: false,
    isCreatingNewCart: false,
    isFatalError: false,
    isFetchingNonEmptyCart: false,
    isInitialized: false,
    popUpWarnings: [],
    timeStamp: null,
    validatingAddress: false,
    isPickupPharmacyUpdated: {},
    isConsentsUpdating: false,
  },
  basket: {
    appliedCoupons: [],
    appliedGiftCards: [],
    autofillCartErrors: [],
    deliveryCalculationSeparate: [],
    countOfPharmacies: [],
    freeShipping: {},
    has3rdPartyProduct: false,
    isOnlyMarketplaceCart: false,
    hasDrMaxAndMarketplaceProducts: false,
    isReservationAllowed: true,
    itemGroups: [],
    itemsToRemove: [],
    itemsUnavailableForReservation: [],
    marketplaceItemGroups: [],
    pendingItems: [],
    sellers: {},
    sellersPrices: [],
    showUnavailableQuantityModal: true,
  },
  firstPartyMarketplacePrices: {
    grandTotal: 0,
  },
  marketplacePrices: {
    grandTotal: 0,
  },
  prices: {
    currency,
    appliedTaxes: [],
    grandTotal: 0,
    subtotalExcludingTax: 0,
    subtotalIncludingTax: 0,
    subtotalWithDiscountExcludingTax: 0,
    subtotalWithDiscountIncludingTax: 0,
  },
  delivery: {
    allProductsUndeliverable: false,
    appliedShippingRestrictions: [],
    boxUnavailabilityReason: null,
    deliveryCalculationBundle: null,
    /** DTC 2 */
    fastestDeliveries: {},
    homeDeliveryData: [],
    pickupPlaceDeliveryData: null,
    hasIncompatibleShippingMethod: false,
    hasReservationChangedToOrder: false,
    isCommonMapEnabled: false,
    loadingShippingMethods: false,
    marketplaceSelectedShippingMethod: '',
    marketplaceShippingMethodOnCart: null,
    marketplaceShippingMethods: [],
    pharmacyDeliveryDates: {},
    pharmacyStockStatuses: {},
    pharmacyUnavailabilityReason: null,
    pickupDriveInId: null,
    pickupPacketaId: null,
    pickupPharmacyDetails: null,
    pickupPharmacyId: null,
    pickupPlaceDeliveryDates: {},
    packetaDeliveryDates: {},

    /** It contains pickup place details for each method code. The object's keys are method codes. */
    pickupPlacesDetails: {},

    /** It contains id for each method code. The object's keys are method codes. */
    pickupPlacesIds: {},

    pickupPostOfficeId: null,
    redirectAgreements: getRedirectAgreements(),
    reservationInfoBox: {},
    restrictionsVerifiedForZip: '',
    savingShippingMethod: false,
    selectedShippingMethod: '',
    shippingMethodOnCart: null,
    shippingMethods: [],
    skusNotAvailableAtPickupPlace: [],
    skusNotAvailableForBox: [],
    skusRestrictions: {},
    stockStatuses: {},
    timeSlotDelivery: {
      postcode: '',
      selectedRoutedId: null,
      hasTimeSlotAvailabilityChanged: false,
    },
  },
  payment: {
    paymentMethods: [],
    marketplacePaymentMethods: [],
    appliedPaymentRestrictions: [],
    acceptedVouchers: {},
    selectedPaymentMethod: '',
    marketplaceSelectedPaymentMethod: '',
    hasIncompatiblePaymentMethod: false,
    loadingPaymentMethods: false,
    savingPaymentMethod: false,
  },
  order: {
    agreements: getDefaultAgreements(),
    existingAgreements: [], // this comes from the agreements api
    consents: [],
    warnings: [],
    submitting: false,
    shouldRedirectToGate: false,
    reservationDisabledByUser: false,
    type: ORDER,

    /**
     * Boolean value that says whether the user approved OTC being delivered
     * by a non-OTC delivery (delivery not optimal for OTC). Connected
     * to OTC approval modal. Implemented only for PL. null means, user haven't seen the modal yet
     *
     * It is initialized from the session storage (which is deleted when tab is closed).
     *
     * @property {boolean}
     */
    isDeliveryOfOtcApprovedByUser: null,
  },
  onlineTotal: 0,
  offlineTotal: 0,
  competition: {
    competitionId: null,
    competitionChannel: '',
    competitionNumberOfCoupons: null,
  },
  features: {
    isCouponVisible: true,
    isGiftCardVisible: true,
    couponWarningMessage: '',
    giftCardWarningMessage: '',
  },
})

export const state = () => ({
  ...getDefaultCartState(),
})

export const mutations = {
  initCartState: (moduleState) => {
    const id = get('checkout/cart/id', '')

    if (!id) {
      set('checkout/cart/id', moduleState.cartId)
    } else if (moduleState.cartId !== id) {
      mutations.setCartId(moduleState, id)
    }

    moduleState.status.isInitialized = true

    if (otcDeliveryApprovalEnabled) {
      moduleState.order.isDeliveryOfOtcApprovedByUser = get(
        'checkout/isDeliveryOfOtcApprovedByUser',
        null,
        'sessionStorage',
      )
    }
  },
  initializeCompetition: (moduleState) => {
    moduleState.competition.competitionChannel = ''
    moduleState.competition.competitionNumberOfCoupons = null
    moduleState.competition.competitionId = null
  },

  removeAgreement(moduleState, agreement) {
    const existingIndex = moduleState.order.agreements.findIndex(
      ({
        channel,
        topic,
      }) => channel === agreement?.channel && topic === agreement?.topic,
    )

    if (existingIndex >= 0) {
      moduleState.order.agreements.splice(existingIndex, 1)
    }
  },

  resetAgreements(moduleState) {
    moduleState.order.agreements = getDefaultAgreements()
  },

  resetCartState: (moduleState) => {
    Object.assign(moduleState, getDefaultCartState())
    set('checkout/cart/id', moduleState.cartId)
    if (otcDeliveryApprovalEnabled) {
      set('checkout/isDeliveryOfOtcApprovedByUser', null, 'sessionStorage')
    }
  },

  setCartId: (moduleState, id) => {
    moduleState.cartId = id
    set('checkout/cart/id', id)
    pushEventCartId(id)
  },

  setCartStateGeneric: setStateByPath,

  setCounter(moduleState, {
    id,
    counter,
  }) {
    // eslint-disable-next-line no-labels
    itemGroupLoop: for (const itemGroup of moduleState.basket.itemGroups) {
      const item = itemGroup.items?.find(({ id: foundId }) => foundId === id)

      for (const rule of itemGroup.rules) {
        const ruleItem = rule.items?.find(({ id: foundId }) => foundId === id)
        const ruleItemForQuantityBlock = rule.items?.find(({ product }) => product.id === item?.product?.id)

        if (ruleItemForQuantityBlock) {
          ruleItemForQuantityBlock.touchedForQuantity = true
        }

        if (ruleItem) {
          ruleItem.counter = counter
          ruleItem.touched = true
          ruleItem.touchedForQuantity = true

          const productId = ruleItem.product.id
          const causedProduct = itemGroup.items.find(({ product }) => product.id === productId)

          if (causedProduct) {
            causedProduct.touchedForQuantity = true
          }

          // eslint-disable-next-line no-labels
          break itemGroupLoop
        }
      }

      if (item) {
        item.counter = counter
        item.touched = true
        item.touchedForQuantity = true
        break
      }
    }
  },

  toggleAgreement(moduleState, { agreement, value }) {
    if (!agreement) {
      return
    }

    const existingIndex = moduleState.order.agreements.findIndex(
      ({
        channel,
        topic,
      }) => channel === agreement.channel && topic === agreement.topic,
    )
    if (existingIndex >= 0) {
      if (value) {
        moduleState.order.agreements[existingIndex].value = 'Y'
      } else {
        moduleState.order.agreements[existingIndex].value = 'N'
      }
    }
  },

  updateAgreement(moduleState, agreement) {
    const existingIndex = moduleState.order.agreements.findIndex(
      ({
        channel,
        topic,
      }) => channel === agreement?.channel && topic === agreement?.topic,
    )

    if (existingIndex >= 0) {
      moduleState.order.agreements[existingIndex] = agreement
    } else {
      moduleState.order.agreements.push(agreement)
    }
  },

  addNewPopUpWarning(moduleState, warning) {
    moduleState.status.popUpWarnings.push(warning)
  },

  removeFirstPopUpWarning(moduleState) {
    moduleState.status.popUpWarnings.shift()
  },
}

export const getters = {
  getIsInitialized: (moduleState) => moduleState.status.isInitialized,

  getId: (moduleState) => moduleState.cartId,

  getItems: (moduleState) => {
    const { items } = getItems(moduleState.basket.itemGroups)
    return items
  },

  /**
   * TODO: move to CheckoutCartGetters when possible
   */
  getShippingMethods: (moduleState, __, rootState) => {
    const {
      delivery: { shippingMethods },
    } = moduleState
    const {
      'checkout/pickupIds': { pickupIds },
    } = rootState

    return shippingMethods.map((shippingMethod) => {
      const simpleDeliveryTime = shippingMethod.simpleDeliveryTime ?? null
      const simpleDeliveryTimeInfo = shippingMethod.simpleDeliveryTimeInfo ?? null
      const courierId = shippingMethod.courierId ?? null
      const drmaxPickupIds = pickupIds[shippingMethod.carrierCode]
      const priceExclTax = shippingMethod.priceExclTax ?? null
      const priceInclTax = shippingMethod.priceInclTax ?? null

      if (shippingMethod.carrierCode === pharmacyCollectCarrierCode) {
        return {
          ...shippingMethod,
          courierId,
          drmaxPickupIds,
          pickupPlaceTypes: ['PHARMACY'],
          simpleDeliveryTime,
          simpleDeliveryTimeInfo,
          priceExclTax,
          priceInclTax,
          pins: [
            {
              type: 'PHARMACY',
              pins: [
                {
                  path: '/img/delivery/cac.svg',
                  status: 'icon',
                },
                {
                  path: '/img/map/pin-pharmacy-enabled.svg',
                  status: 'enabled',
                },
                {
                  path: '/img/map/pin-pharmacy-disabled.svg',
                  status: 'disabled',
                },
                {
                  path: '/img/map/pin-pharmacy-selected.svg',
                  status: 'selected',
                },
                {
                  status: 'unknown',
                }
              ]
            }
          ]
        }
      }
      if (shippingMethod.carrierCode === packetaCollectCarrierCode) {
        return {
          ...shippingMethod,
          courierId,
          drmaxPickupIds,
          pickupPlaceTypes: ['PACKETA'],
          simpleDeliveryTime,
          simpleDeliveryTimeInfo,
          priceExclTax,
          priceInclTax,
          pins: [
            {
              type: 'PACKETA',
              pins: [
                {
                  path: '/img/delivery/packeta.svg',
                  status: 'icon',
                },
                {
                  path: '/img/map/pin-packeta-enabled.svg',
                  status: 'enabled',
                },
                {
                  path: '/img/map/pin-packeta-disabled.svg',
                  status: 'disabled',
                },
                {
                  path: '/img/map/pin-packeta-selected.svg',
                  status: 'selected',
                },
                {
                  status: 'unknown',
                }
              ]
            }
          ]
        }
      }
      return {
        ...shippingMethod,
        courierId,
        drmaxPickupIds,
        simpleDeliveryTime,
        simpleDeliveryTimeInfo,
      }
    }).filter(({ carrierCode }) => !forbiddenCarrierCodes.includes(carrierCode))
  },

  getIntegrationIdsForPacketaPickupPlace: (moduleState) => {
    const {
      delivery: { shippingMethods },
    } = moduleState

    return shippingMethods
      .filter(({ carrierCode, methodCode }) => (
        carrierCode === 'drmaxpickupplace' && methodCode === 'packeta'
      ))
      .map(({ integrationId }) => integrationId)[0]
  },

  getMarketplaceShippingMethods: (moduleState) => moduleState.delivery.marketplaceShippingMethods,

  getPickupPlaceId: (moduleState) => {
    const methodCode = moduleState.delivery.selectedShippingMethod.split('~')[1]

    return moduleState.delivery.pickupPlacesIds[methodCode]
  },

  getPickupPlaceDetails: (moduleState) => {
    const methodCode = moduleState.delivery.selectedShippingMethod.split('~')[1]

    return moduleState.delivery.pickupPlacesDetails[methodCode] ?? null
  },
}

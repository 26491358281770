import logger from '@nsf/my-account/logger.js'
import { decodeAndSetTokens, getRefreshToken } from '../utils/auth.js'
import { client, isSorpCustomerService } from '../clients/MyAccountClient.js'

export const refreshToken = async (refreshTokenValue) => {
  try {
    const path = isSorpCustomerService ? '/client/login/refresh' : '/login/refresh'

    const {
      data, ok, status,
    } = await client.post(path, { refreshToken: refreshTokenValue || getRefreshToken() })

    if (ok) {
      decodeAndSetTokens(data)

      return { ok: true }
    }

    throw new Error(`${status} ${isSorpCustomerService ? data : data?.detail}`)
  } catch (e) {
    logger.error(
      'refreshToken(***) failed: %s',
      e.message,
    )

    return { ok: false }
  }
}

export const login = async (payload) => {
  try {
    const path = isSorpCustomerService ? '/client/login' : '/login'

    const { data, ok, status } = await client.post(path, {
      username: payload.email,
      password: payload.password,
    })

    if (ok) {
      decodeAndSetTokens(data)

      return {
        ok: true,
        status,
        message: '',
      }
    }

    return {
      ok: false,
      status,
      message: data?.message,
    }
  } catch (e) {
    logger.error(
      'login(***) failed: %s',
      e.message,
    )

    return {
      ok: false,
      status: 500,
      message: e?.message,
    }
  }
}

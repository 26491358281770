export const isUnauthorizedStatus = (status) => [400, 401].includes(status)
export const isAttemptLimitReachedStatus = (status) => [409].includes(status)
export const isBadPasswordStatus = (status, message) => [401].includes(status) && message.includes('Bad password')
export const isServerErrorStatus = (status) => [500].includes(status)
export const isWrongMethodStatus = (status) => status === 405

export const BAD_PASSWORD = 'badPassword'
export const UNAUTHORIZED = 'unauthorized'
export const MISSING_CONFIRMATION = 'missingConfirmation'
export const ATTEMPT_LIMIT_REACHED = 'attemptLimitReached'
export const OTHER = 'other'

export const getLoginError = (status, message = '') => {
  if (status < 300) {
    return null
  }
  if (isBadPasswordStatus(status, message)) {
    return BAD_PASSWORD
  }
  if (isUnauthorizedStatus(status)) {
    return UNAUTHORIZED
  }
  if (status === 403) {
    return MISSING_CONFIRMATION
  }
  if (isAttemptLimitReachedStatus(status)) {
    return ATTEMPT_LIMIT_REACHED
  }
  return OTHER
}
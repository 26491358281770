import { Query } from '@nsf/core/ElasticSearch.js'
import { mapDataToRelationshipLoaderProducts } from '@nsf/product-relationship/mappers/ProductLoaderMapper.js'
import { persooLoaderLabel } from '@nsf/product-relationship/mixins/RelationshipLoader.js'
import { listingRequiredFields } from '@nsf/catalog/utils/FieldsUtils.js'
import logger from '@nsf/product-relationship-loader-persoo/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  productRelationshipLoaderPersoo: {
    persooEnabled,
  },
} = useAppConfig()

const ATTEMPT_MAX_COUNT = 3
const ATTEMPT_RETRY_IN_MS = 1000

async function productLoader(loaderType, fn, identifier, queryParams = {}, attempt = 1) {
  if (!persooEnabled) {
    return { products: [] }
  } if (!window.persoo) {
    logger.warn(`Product Loader: Attempt (${attempt}/${ATTEMPT_MAX_COUNT}) to get recommended products failed. Global "persoo" object is undefined.
        ${attempt < ATTEMPT_MAX_COUNT ? `Retry in ${ATTEMPT_RETRY_IN_MS}ms.` : ''}`)
    if (attempt < ATTEMPT_MAX_COUNT) {
      setTimeout(() => productLoader(loaderType, fn, identifier, queryParams, ++attempt), ATTEMPT_RETRY_IN_MS)
    }
    return { products: [] }
  }

  try {
    const { size } = queryParams

    const { productIds } = await fn(identifier)

    const query = Query.products()
      .whereIn('id', productIds)
      .whereIn('drmax_pim_status', ['Available', 'Special sale off'])
      .only(listingRequiredFields)

    if (!isNaN(size)) {
      query.size(size)
    }

    const products = await query.getAll()
    const orderedProducts = productIds.map((id) => products.find((pr) => id === pr.id)).filter((pr) => pr)

    const orderedProductsF = !orderedProducts?.length ? [] : mapDataToRelationshipLoaderProducts(orderedProducts, persooLoaderLabel, loaderType)

    return { products: orderedProductsF }
  } catch (e) {
    logger.error('Get related products failed: %s', e)
    return { products: [] }
  }
}

/**
 * Calls Persoo function for getting ids and returns products from them.
 * @param identifier {number, Array}
 * @param fn {function(id: number): number[]}
 * @param queryParams
 * @returns {Promise<ProductItem[]>}
 */
export default productLoader

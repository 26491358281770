import { mapState } from 'vuex'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    myAccountSorpEnabled,
  },
} = useRuntimeConfig()

export default {
  created() {
    this.myAccountSorpEnabled = myAccountSorpEnabled
  },

  computed: {
    ...mapState(
      'my-account/user',
      [
        'user',
        'isLoggedIn',
        'isLoggingIn',
        'isInitialized',
      ],
    ),
    isLimitedUserAccount() {
      return this?.user?.accountType === 'E'
    },
    isFullUserAccount() {
      return this?.user?.accountType === 'O'
    },
  },
}

import { mapDataToModel } from '@nsf/core/Mapper.js'

const mapDataToItem = (data, translations = {}) => mapDataToModel(data, translations)
  .map((item) => ({
    ...item,
    counter: item.quantity,
    touched: false,
    touchedForQuantity: false,
  }))

export default mapDataToItem

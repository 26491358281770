import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import Vue from "vue";
import { compile } from "../utils";
import NuxtError from "../../app/layouts/error.vue";
import NuxtChild from "./nuxt-child";
export default {
  name: "Nuxt",
  components: {
    NuxtChild: NuxtChild,
    NuxtError: NuxtError
  },
  props: {
    nuxtChildKey: {
      type: String,
      default: void 0
    },
    keepAlive: Boolean,
    keepAliveProps: {
      type: Object,
      default: void 0
    },
    name: {
      type: String,
      default: "default"
    }
  },
  errorCaptured: function errorCaptured(error) {
    if (this.displayingNuxtError) {
      this.errorFromNuxtError = error;
      this.$forceUpdate();
    }
  },
  computed: {
    routerViewKey: function routerViewKey() {
      if (typeof this.nuxtChildKey !== "undefined" || this.$route.matched.length > 1) {
        return this.nuxtChildKey || compile(this.$route.matched[0].path)(this.$route.params);
      }
      var _this$$route$matched = _slicedToArray(this.$route.matched, 1),
        matchedRoute = _this$$route$matched[0];
      if (!matchedRoute) {
        return this.$route.path;
      }
      var Component = matchedRoute.components.default;
      if (Component && Component.options) {
        var options = Component.options;
        if (options.key) {
          return typeof options.key === "function" ? options.key(this.$route) : options.key;
        }
      }
      var strict = /\/$/.test(matchedRoute.path);
      return strict ? this.$route.path : this.$route.path.replace(/\/$/, "");
    }
  },
  beforeCreate: function beforeCreate() {
    Vue.util.defineReactive(this, "nuxt", this.$root.$options.nuxt);
  },
  render: function render(h) {
    var _this = this;
    if (!this.nuxt.err || process.client && !this.nuxt.errPageReady) {
      return h("NuxtChild", {
        key: this.routerViewKey,
        props: this.$props
      });
    }
    if (this.errorFromNuxtError) {
      this.$nextTick(function () {
        return _this.errorFromNuxtError = false;
      });
      return h("div", {}, [h("h2", "An error occurred while showing the error page"), h("p", "Unfortunately an error occurred and while showing the error page another error occurred"), h("p", "Error details: ".concat(this.errorFromNuxtError.toString())), h("nuxt-link", {
        props: {
          to: "/"
        }
      }, "Go back to home")]);
    }
    this.displayingNuxtError = true;
    this.$nextTick(function () {
      return _this.displayingNuxtError = false;
    });
    return h(NuxtError, {
      props: {
        error: this.nuxt.err
      }
    });
  }
};
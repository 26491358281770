import logger from '@nsf/cms/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const { appUrlInternal, public: { appUrl } } = useRuntimeConfig()

const getUrl = () => appUrlInternal || appUrl

export const getContentBlock = async (blockId, ignoreCache = false) => {
  try {
    const response = await fetch(`${getUrl()}/cms-block/${blockId}${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getContentBlock() failed: %s', e.message)
    return null
  }
}

export const getAnnouncement = async (ignoreCache = false) => {
  try {
    const response = await fetch(`${getUrl()}/cms-announcement-info${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getAnnouncement() failed: %s', e.message)
    return null
  }
}

export const getPageById = async (pageId, ignoreCache) => {
  try {
    const response = await fetch(`${getUrl()}/cms-page/${pageId}${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getPageById() failed: %s', e.message)
    return null
  }
}

export const getLinkTreeById = async (linkTreeId, ignoreCache) => {
  try {
    const response = await fetch(`${getUrl()}/cms-link-tree/${linkTreeId}${ignoreCache ? '?ignoreCache=true' : ''}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getLinkTreeById() failed: %s', e.message)
    return null
  }
}

export const getPageByPageIdentifier = async (pageIdentifier) => {
  try {
    const response = await fetch(`${getUrl()}/cms-page-by-identifier/${pageIdentifier}`)

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getPageByPageIdentifier() failed: %s', e.message)
    return null
  }
}

export const getPageBuilderData = async (data) => {
  try {
    const response = await fetch(
      `${getUrl()}/pagebuilder-data`,
      {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: { 'Content-Type': 'application/json' },
      },
    )

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getPageBuilderData() failed: %s', e.message)
    return null
  }
}

export const getAuthors = async (page = 1, limit = 20, authorId = null, authorUrlKey = null) => {
  try {
    const filters = {}

    if (authorId) {
      // eslint-disable-next-line camelcase
      filters.entity_id = {
        value: parseInt(authorId, 10),
        operator: 'is',
      }
    }

    if (authorUrlKey) {
      // eslint-disable-next-line camelcase
      filters.url_key = {
        value: authorUrlKey,
        operator: 'is',
      }
    }

    const data = {
      filters,
      pager: {
        page,
        limit,
      },
    }

    const response = await fetch(
      `${getUrl()}/cms-blog-author`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data }),
      },
    )

    if (response.status !== 200) {
      throw new Error('Invalid response from CMS Service')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('CmsServiceRepository').error('getAuthors() failed: %s', e.message)
    return null
  }
}

export const getAuthorById = async (authorId) => {
  const authors = await getAuthors(1, 1, authorId)

  if (authors?.items?.length) {
    return authors.items[0]
  }

  return null
}

export const getAuthorByUrlKey = async (urlKey) => {
  const authors = await getAuthors(1, 1, null, urlKey)

  if (authors?.items?.length) {
    return authors.items[0]
  }

  return null
}

export const resolveCmsPageById = async (pageId, ignoreCache) => {
  const response = await getPageById(pageId, ignoreCache)
  if (!response) {
    return {
      statusCode: 404,
    }
  }

  return response
}

export const resolveLinkTreeById = async (linkTreeId, ignoreCache) => {
  const response = await getLinkTreeById(linkTreeId, ignoreCache)
  if (!response) {
    return {
      statusCode: 404,
    }
  }

  return response
}

export const resolveCmsBlogAuthorById = async (authorId, ignoreCache) => {
  const response = await getAuthorById(authorId, ignoreCache)
  if (!response) {
    return {
      statusCode: 404,
    }
  }

  return response
}

import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { buildBabyClub } from '@nsf/clubs/utils/index.js'

const {
  myAccount: {
    features: {
      agreementsSmsChannel,
      myAccountSorpCardCodeToOtpModeEnabled,
      myAccountSorpFromCustomerServiceEnabled,
    },
  },
  rootConfig: {
    global: {
      agreementsTopic,
    },
  },
} = useAppConfig()

export const mapAgreements = (data) => {
  const agreements = []
  if (data.agreements?.ppdAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'N')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'N')
  }
  if (data.agreements?.mktAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'N')
  }
  if (myAccountSorpCardCodeToOtpModeEnabled) {
    pushIntoAgreements(agreements, '3RD', 'CLIENT', data.agreements?.thirdPartyMarketingAgreement ? 'Y' : 'N')
  }
  if (data.agreements?.loyaltyAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'EMAIL', 'CP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'CP', 'Y')
    if (!myAccountSorpFromCustomerServiceEnabled) {
      pushIntoAgreements(agreements, 'POST', 'CP', 'Y')
    }
    if (myAccountSorpCardCodeToOtpModeEnabled) {
      pushIntoAgreements(agreements, '3RD', 'CLIENT', data.agreements?.thirdPartyMarketingAgreement ? 'Y' : 'N')
      pushIntoAgreements(agreements, 'EBLOK', 'CLIENT', data.agreements?.eblokAgreement ? 'Y' : 'N')
    }
  }
  return agreements
}

const generateAgreement = (channel, topic, value) => ({ channel, topic, value })

function pushIntoAgreements(agreements, channel, topic, value) {
  const agreement = agreements.find(
    (agreementItem) => agreementItem.channel === channel && agreementItem.topic === topic,
  )
  if (agreement) {
    agreement.value = value
  } else {
    agreements.push(generateAgreement(channel, topic, value))
  }
}

export const mapAgreementsMyAccount = (data) => {
  const agreements = []

  if (data.isBabyClub) {
    agreements.push(buildBabyClub(data))
  }

  if (data.shop_email !== undefined) {
    agreements.push({
      channel: 'EMAIL',
      topic: 'ESHOP',
      value: data.shop_email ? 'Y' : 'N',
    })
  }

  if (data.shop_sms !== undefined) {
    agreements.push({
      channel: 'SMS',
      topic: 'ESHOP',
      value: data.shop_sms ? 'Y' : 'N',
    })
  }

  if (data.shop_post !== undefined) {
    agreements.push({
      channel: 'POST',
      topic: 'ESHOP',
      value: data.shop_post ? 'Y' : 'N',
    })
  }

  if (data.emailSmsAgreement !== undefined) {
    for (const channel of agreementsSmsChannel) {
      agreements.push({
        channel,
        topic: agreementsTopic,
        value: data.emailSmsAgreement ? 'Y' : 'N',
      })
    }
  }

  if (data.thirdPartyMarketingAgreement !== undefined) {
    agreements.push({
      channel: '3RD',
      topic: agreementsTopic,
      value: data.thirdPartyMarketingAgreement ? 'Y' : 'N',
    })
  }

  if (data.eblokAgreement !== undefined) {
    agreements.push({
      channel: 'EBLOK',
      topic: agreementsTopic,
      value: data.eblokAgreement ? 'Y' : 'N',
    })
  }

  if (agreements.length === 0) {
    return undefined
  }

  return agreements
}

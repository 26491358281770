import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { getRequestHeader } from "h3";
import { detect } from "detect-browser";
import compareVersions from "compare-versions";
export default function (event) {
  var isCacheable = true;
  var isPartial = false;
  var isUnsupported = false;
  var _useLegacyBrowsersOpt = useLegacyBrowsersOptions(),
    groups = _useLegacyBrowsersOpt.groups;
  var ua = getRequestHeader(event, "user-agent");
  var browser = detect(ua);
  if (browser && !browser.bot) {
    var partial = groups.partial[browser.name];
    isPartial = partial && compareVersions.compare.apply(compareVersions, [browser.version].concat(_toConsumableArray(partial)));
    var unsupported = groups.unsupported[browser.name];
    isUnsupported = unsupported && compareVersions.compare.apply(compareVersions, [browser.version].concat(_toConsumableArray(unsupported)));
  }
  if (!browser || isPartial || isUnsupported) {
    isCacheable = false;
  }
  return {
    isBrowser: !!browser,
    isCacheable: isCacheable,
    isPartial: isPartial,
    isUnsupported: isUnsupported
  };
}
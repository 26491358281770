import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var AddressValidation = function AddressValidation() {
  return import("../../layers/address-validation/app/components/AddressValidation.vue"
  /* webpackChunkName: "components/address-validation" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddressValidationModal = function AddressValidationModal() {
  return import("../../layers/address-validation/app/components/AddressValidationModal.vue"
  /* webpackChunkName: "components/address-validation-modal" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ModalConfirmAge = function ModalConfirmAge() {
  return import("../../layers/adults-only/app/components/ModalConfirmAge.vue"
  /* webpackChunkName: "components/modal-confirm-age" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SpecialOfferBanner = function SpecialOfferBanner() {
  return import("../../layers/special-offers/app/components/SpecialOfferBanner.vue"
  /* webpackChunkName: "components/special-offer-banner" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var HealthCertificateLogo = function HealthCertificateLogo() {
  return import("../../layers/sukl-widget/app/components/HealthCertificateLogo.vue"
  /* webpackChunkName: "components/health-certificate-logo" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SuklFooter = function SuklFooter() {
  return import("../../layers/sukl-widget/app/components/SuklFooter.vue"
  /* webpackChunkName: "components/sukl-footer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SuklWrapper = function SuklWrapper() {
  return import("../../layers/sukl-widget/app/components/SuklWrapper.vue"
  /* webpackChunkName: "components/sukl-wrapper" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}
import {
  isArray, isNullish, isObject, isString,
} from '@nsf/core/Utils.js'

export const extract = (string, regex) => {
  if (!isString(string)) {
    return ''
  }

  const match = string.match(regex)

  return match ? match[0] : match
}

export const extractBorderRadius = (string) => {
  let extracted = ''
  extracted += extract(string, /border-radius: .+?;/) || ''
  extracted += extract(string, /border-top-left-radius: .+?;/) || ''
  extracted += extract(string, /border-top-right-radius: .+?;/) || ''
  extracted += extract(string, /border-bottom-left-radius: .+?;/) || ''
  extracted += extract(string, /border-bottom-right-radius: .+?;/) || ''
  return extracted
}

export const extractBorder = (string) => {
  let extracted = ''
  extracted += extract(string, /border-style: .+?;/) || ''
  extracted += extract(string, /border-color: .+?;/) || ''
  extracted += extract(string, /border-width: .+?;/) || ''
  return extracted
}

export const extractMinHeight = (string) => extract(string, /min-height: .+?;/) || ''

export const extractChildren = (ctx) => ctx.props.data.children || []

export const extractHtmlProperty = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  let value = ''

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    value = o.map((o) => extractHtmlProperty(o, depth)).join('')
  }
  if (isObject(o)) {
    value = o.html || extractHtmlProperty(o.children || o.items, depth - 1)
  }

  return value
}

export const extractHtml = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  let value = ''

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    value = o.map((o) => extractHtml(o, depth)).join('')
  }
  if (isObject(o)) {
    value = o.html || o.value || extractHtml(o.children || o.items, depth - 1)

    if (o.tag && !(['', 'none'].includes(o.tag))) {
      const keys = Object.keys(o)

      let attrs = ''
      for (const key of keys) {
        if (!['children', 'tag'].includes(key)) {
          // eslint-disable-next-line max-depth
          if (key) {
            attrs = `${attrs} ${key}`
          }
          // eslint-disable-next-line max-depth
          if (o[key]) {
            attrs = `${attrs}="${o[key]}"`
          }
        }
      }

      if (['img', 'br', 'hr', 'input', 'link', 'base', 'area', 'meta'].includes(o.tag)) {
        value = `<${o.tag}${attrs}>`
      } else {
        value = `<${o.tag}${attrs}>${value}</${o.tag}>`
      }
    }
  }

  return value
}

export const extractStyle = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractStyle(o, depth)).join('; ')
  }
  if (isObject(o)) {
    return o.style || extractStyle(o.children, depth - 1)
  }

  return ''
}

export const extractMobileStyle = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractMobileStyle(o, depth)).join('; ')
  }
  if (isObject(o)) {
    return o?.['mobile-style'] || extractMobileStyle(o.children, depth - 1)
  }

  return ''
}

export const extractClass = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractClass(o, depth)).join('; ')
  }
  if (isObject(o)) {
    return o.class || extractClass(o.children, depth - 1)
  }

  return ''
}

export const extractSrc = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractSrc(o, depth))?.[0]
  }
  if (isObject(o)) {
    return o.src || extractSrc(o.children, depth - 1)
  }

  return ''
}

export const extractAlt = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractAlt(o, depth))?.[0]
  }
  if (isObject(o)) {
    return o.alt || extractAlt(o.children, depth - 1)
  }

  return ''
}

export const extractHref = (o, depth = Infinity) => {
  if (depth < 0) {
    return ''
  }

  if (isArray(o)) {
    // eslint-disable-next-line no-shadow
    return o.map((o) => extractHref(o, depth))?.[0]
  }
  if (isObject(o)) {
    return o.href || extractHref(o.children, depth - 1)
  }

  return ''
}

export const findAll = (o, predicate, depth = Infinity) => {
  if (isNullish(o)) {
    return []
  }
  if (depth < 0) {
    return []
  }

  const found = []

  if (isArray(o)) {
    for (const val of o) {
      found.push(...findAll(val, predicate, depth - 1))
    }
  }

  if (predicate(o)) {
    found.push(o)
  }

  if (isObject) {
    found.push(...findAll(o.children, predicate, depth - 1))
  }

  return found
}

export const findFirstElement = (o, predicate, depth = Infinity) => {
  if (isNullish(o)) {
    return null
  }
  if (depth < 0) {
    return null
  }

  if (isArray(o)) {
    for (const val of o) {
      const f = findFirstElement(val, predicate, depth - 1)
      if (f) {
        return f
      }
    }
  }

  if (predicate(o)) {
    return o
  }

  if (isObject) {
    const f = findFirstElement(o.children, predicate, depth - 1)
    if (f) {
      return f
    }
  }

  return null
}

export const findDataElement = (type, source) => {
  if (!source.children) {
    return null
  }

  for (const item of source.children) {
    const property = item['data-element'] !== type
      ? findDataElement(type, item)
      : item

    if (property) {
      return property
    }
  }

  return null
}

export const findDataContentType = (type, source, checkStartsWith = false) => {
  if (!source.children) {
    return null
  }

  for (const item of source.children) {
    const property = (item['data-content-type'] === type || (checkStartsWith && item['data-content-type']?.startsWith(type)))
      ? item
      : findDataContentType(type, item, checkStartsWith)

    if (property) {
      return property
    }
  }

  return null
}

export const extractTableOfContentForPagebuilder = (data) => {
  // List of anchors for Table of contents
  const anchors = []

  // Find all h2 headings in content
  const headings = findAll(data, (o) => ['h2'].includes(o.tag))

  // generate anchors for Table of contents
  headings.forEach((heading) => {
    const headingContent = extractHtmlProperty(heading).trim()

    if (headingContent) {
      const htmlContent = headingContent.replace(/(<([^>]+)>)/gi, '')

      anchors.push({
        id: heading.id,
        html: htmlContent,
        tag: heading.tag,
      })
    }
  })

  return anchors
}

export const getPropsData = (ctx, key) => ctx.props.data[key] ?? ''

export const generateHTML = (json) => {
  // Helper function to process each node recursively
  function processNode(node) {
    if (node.tag === 'none') {
      return node.html || ''
    }

    let attributes = ''
    if (node.style) {
      attributes += ` style="${node.style}"`
    }
    if (node.href) {
      attributes += ` href="${node.href}"`
    }

    let childrenHTML = ''
    if (node.children && node.children.length > 0) {
      childrenHTML = node.children.map(processNode).join('')
    }

    return `<${node.tag}${attributes}>${childrenHTML}</${node.tag}>`
  }

  // Start processing from the root node
  return json.children.map(processNode).join('')
}

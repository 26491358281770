import { mapDataToModel } from '@nsf/core/Mapper.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'

export const mapHitToArticle = (data, translations = {}) => {
  const article = mapDataToModel(data, {
    // eslint-disable-next-line camelcase
    date_published: 'publishedAt',
    uri: 'urlKey',
    name: 'title',
    perex: 'shortContent',
    // eslint-disable-next-line camelcase
    thumbnail_url: 'postThumbnail',
    ...translations,
  })

  article.name = article.title

  if (article.tags?.length > 0) {
    article.tags = article.tags
      .flatMap((item) => (typeof item === 'string' ? item?.split(';') : [item.title]))
      .map((item) => ({ name: item }))
  }

  return article
}

export const mapHitToProduct = (data, translations = {}) => mapDataToModel(data, {
  ...translations,
})

export const mapHitToCategory = (data, translations = {}) => mapDataToModel(data, {
  uri: 'urlPath',
  ...translations,
})

export const mapHitToBrand = (data, translations = {}) => mapDataToModel(data, {
  ...translations,
})

export const mapHitToProductLine = (data, translations = {}) => {
  const productLine = mapDataToModel(data, {
    ...translations,
  })

  productLine.url = data.uri
  return productLine
}

export const mapDataToAdvice = (data, translations = {}) => mapDataToModel(data, {
  ...translations,
})

export const mapDataToHits = (data, translations = {}) => {
  const hits = []

  for (const dat of data) {
    switch (dat.indexType) {
      case 'PRODUCTS':
        hits.push(mapHitToProduct(dat, translations))
        break
      case 'CATEGORIES':
        hits.push(mapHitToCategory(dat, translations))
        break
      case 'BRANDS':
        hits.push(mapHitToBrand(dat, translations))
        break
      case 'ARTICLES_ADVICE':
        hits.push(mapDataToAdvice(dat, translations))
        break
      case 'ARTICLES_CMS':
        hits.push(mapHitToArticle(dat, translations))
        break
      case 'PRODUCT_LINES':
        hits.push(mapHitToProductLine(dat, translations))
        break
      default:
        hits.push(dat)
        break
    }
  }

  return hits
}

export const mapDataToSearches = (data) => ensureArray(mapDataToModel(data)).map((search) => search.search)

export default {
  footer: {
    links: [
      {
        url: '/kim-jestesmy',
        text: 'O nas',
      },
      {
        url: '/faq',
        text: 'Pomoc',
      },
      {
        url: '/bezpieczne-zakupy',
        text: 'Bezpieczeństwo',
      },
    ],
    legalText: 'Wszystkie ceny podawane w serwisie na stronie https://www.drmax.pl/ mają charakter ceny maksymalnej w wypadku złożenia rezerwacji w tym serwisie. Każda Apteka może określić własną cenę odsprzedaży dla danego produktu zarezerwowanego poprzez serwis https://www.drmax.pl/ nie wyższą niż cena maksymalna podana w serwisie.',
  },
}

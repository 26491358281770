import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default (withOrder, withReservation) => Query.named('pickupPlace')
  .toDeliveryCalculator()
  .where('id', variable('pickupPlaceId', 'String!'))
  .where('products', variable('products', '[ProductInput!]'))
  .fields([
    field('id'),
    ...withOrder
      ? [field('order', [
          field('available'),
          field('message'),
          field('unavailableItems', [
            field('sku'),
            field('quantity'),
          ]),
          field('pickupTimes', [
            field('cutoffTime'),
            field('pickupTime'),
            field('deliveryPlan', [
              field('pickupTime'),
              field('primarySource', [
                field('connection', [
                  field('deliveryRouteId'),
                  field('sourceId'),
                  field('sourceType'),
                  field('destinationId'),
                  field('deliveryMethod'),
                ]),
              ]),
            ]),
          ]),
        ])]
      : [],
    ...withReservation
      ? [field('reservation', [
          field('available'),
          field('message'),
          field('unavailableItems', [
            field('sku'),
            field('quantity'),
          ]),
          field('pickupTimes', [
            field('cutoffTime'),
            field('leadTime'),
            field('pickupTime'),
          ]),
        ])]
      : [],
  ])

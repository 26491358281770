export default function (htmlElement, src) {
  onMounted(function () {
    var _htmlElement$value;
    var scriptTag = document.createElement("script");
    scriptTag.setAttribute("id", "CookieDeclaration");
    scriptTag.setAttribute("src", src);
    scriptTag.setAttribute("type", "text/javascript");
    scriptTag.setAttribute("async", "true");
    htmlElement === null || htmlElement === void 0 ? void 0 : (_htmlElement$value = htmlElement.value) === null || _htmlElement$value === void 0 ? void 0 : _htmlElement$value.appendChild(scriptTag);
  });
}
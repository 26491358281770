import Vue from 'vue'
import { get } from '@nsf/core/Storage.js'
import {
  isPhoneCode, isString, isNullish, isStringEmpty,
} from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  checkout: {
    features: {
      alwaysDefaultPhoneCodeEnabled,
      customerNotesEnabled,
      itInvoicingEnabled,
      privateInvoiceEnabled,
      removeSpacesFromZipForMagentoEnabled,
      shippingAddressNameEnabled,
    },
  },
  rootConfig: {
    global: {
      crnEnabled,
      phoneCode: defaultPhoneCode,
      regionSelectEnabled,
      skDicEnabled,
      streetNumberEnabled,
      vatIdEnabled,
    },
  },
} = useAppConfig()

const { public: { countryCode: defaultCountryCode } } = useRuntimeConfig()

export const addressValidationExceptions = [
  ...!streetNumberEnabled ? ['streetNumber'] : [],
  ...!regionSelectEnabled ? ['regionCode'] : [],
  ...!skDicEnabled ? ['skDic'] : [],
  ...!itInvoicingEnabled ? ['itPec'] : [],
  ...!itInvoicingEnabled ? ['itSdi'] : [],
  ...!crnEnabled ? ['companyRegistrationNumber'] : [],
  ...!vatIdEnabled ? ['vatId'] : [],
  'customerNotes',
]

export const addressFields = [
  'street',
  'streetNumber',
  'city',
  'postcode',
]

export const invoiceRecipientType = {
  COMPANY: 'company',
  PRIVATE: 'private',
}

export const getNames = () => ({
  firstname: '',
  lastname: '',
})

export const getDefaultContact = () => ({
  ...getNames(),
  phoneCode: defaultPhoneCode || '',
  phoneNumber: '',
})

export const getDefaultAddress = () => ({
  street: '',
  ...streetNumberEnabled && { streetNumber: '' },
  city: '',
  postcode: '',
  regionCode: '',
  countryCode: defaultCountryCode,
  ...customerNotesEnabled && { customerNotes: '' },
})

export const getDefaultShippingAddress = () => {
  const { customerNotes, ...address } = getDefaultAddress()
  return {
    ...address,
    ...shippingAddressNameEnabled && getNames(),
  }
}

export const getDefaultCompany = () => ({
  companyName: '',
  ...crnEnabled && { companyRegistrationNumber: '' },
  ...vatIdEnabled && { vatId: '' },
  ...(privateInvoiceEnabled) && {
    isInvoiceRequested: false,
    invoiceRecipientType: null,
    selectedRecipientType: invoiceRecipientType.PRIVATE,
  },
  ...skDicEnabled && { skDic: '' },
  ...itInvoicingEnabled && {
    itPec: '',
    itSdi: '',
  },
})

export const getDefaultPersonalDetails = () => ({
  itFiscalCode: '',
})

export const getDefaultBillingMeta = () => ({
  isValid: {
    email: false,
    contact: false,
    address: false,
    company: false,
  },
  isUsed: {
    optionalBillingInfo: false,
    company: false,
    personal: false,
  },
})

export const getDefaultShippingMeta = () => ({
  isValid: {
    address: false,
  },
  isUsed: {
    address: false,
  },
})

export const getDefaultMarketplaceShippingMeta = () => ({
  isValid: {
    address: false,
  },
})

export const getShippingAddressFromLocalStorage = () => {
  const shippingAddressFromLocalStorage = get('checkout/shippingAddress', getDefaultShippingAddress())
  return { ...shippingAddressFromLocalStorage, countryCode: defaultCountryCode }
}

export const getMarketplaceShippingAddressFromLocalStorage = () => {
  const shippingAddressFromLocalStorage = get('checkout/marketplaceShippingAddress', getDefaultShippingAddress())
  return { ...shippingAddressFromLocalStorage, countryCode: defaultCountryCode }
}

export const getBillingAddressFromLocalStorage = () => get('checkout/billingAddress', getDefaultAddress())

export const getContactInfoFromLocalStorage = () => {
  // This is to make this compatible with the old version
  const {
    phoneNumber, telephone, phoneCode, ...rest
  } = get('checkout/contactInfo', getDefaultContact())
  return {
    phoneNumber: phoneNumber || telephone || '',
    phoneCode: !alwaysDefaultPhoneCodeEnabled && isPhoneCode(phoneCode) ? phoneCode : defaultPhoneCode,
    ...rest,
  }
}

export const getCompanyDetailsFromLocalStorage = () => get('checkout/companyDetails', getDefaultCompany())

export const getEmailFromLocalStorage = () => get('checkout/email', '')

export const isValid = (object, exceptions = []) => {
  const data = { ...object }

  exceptions.forEach((exception) => {
    Vue.delete(data, exception)
  })

  return Object
    .values(data)
    .every((value) => (isString(value) ? value.trim() : value))
}

export const areAddressesIdentical = (addressOne, addressTwo) => (
  Object
    .keys(addressOne)
    .every((key) => {
      if ((isNullish(addressOne[key]) || isStringEmpty(addressOne[key], true))
        && (isNullish(addressTwo[key]) || isStringEmpty(addressTwo[key], true))) {
        return true
      }
      return addressOne[key] === addressTwo[key]
    })
)

export const mapBillingAddressFromLogin = ({
  street, streetNumber, city, zip, regionCode, countryCode,
}) => ({
  street,
  streetNumber,
  city,
  postcode: zip,
  regionCode,
  countryCode: countryCode || defaultCountryCode,
})

export const mapShippingAddressFromLogin = ({
  street, streetNumber, city, zip, regionCode, firstName, lastName,
}) => ({
  street,
  streetNumber,
  city,
  postcode: zip,
  regionCode,
  countryCode: defaultCountryCode,
  firstname: firstName,
  lastname: lastName,
})

export const mapContactInfoFromLogin = ({
  firstName, lastName, phoneCode, phoneNumber,
}) => ({
  firstname: firstName,
  lastname: lastName,
  phoneNumber,
  phoneCode: !alwaysDefaultPhoneCodeEnabled && isPhoneCode(phoneCode) ? phoneCode : defaultPhoneCode,
})

export const mapEmailFromLogin = ({ email }) => email

export const mapCompanyFromLogin = ({
  companyName, vatId, companyRegistrationNumber, skDic, sdi, pec,
}) => ({
  companyName: companyName || '',
  vatId: vatId || '',
  skDic: skDic || '',
  itSdi: sdi || '',
  itPec: pec || '',
  companyRegistrationNumber: companyRegistrationNumber || '',
})

export const mapPostcodeForMagento = (postcode) => {
  if (removeSpacesFromZipForMagentoEnabled) {
    return postcode.replace(' ', '')
  }
  return postcode
}

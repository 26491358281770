/* eslint-disable sort-keys */
import { customerGetGroupCodeQuery } from '@ioc/graphql/queries.js'
import { withDefault } from '@nsf/core/Mapper.js'
import logger from '@nsf/my-account/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { client, isSorpCustomerService } from '../clients/MyAccountClient.js'
import {
  mapAdvancedAgreements,
  mapDataToUser,
  mapMissingPersonalDetailsToData,
  mapUserToData,
} from '../mappers/UserMapper.js'
import { getAuthorization, removeTokens } from '../utils/auth.js'
import { withAuth } from '../utils/authDecorator.js'
import { mapAgreements } from '../mappers/AgreementsMapper.js'
import { getStatusBasedOkResponse } from '../utils/index.js'
import { ERROR_MESSAGES } from '../utils/errorMessage.js'

const {
  rootConfig: {
    global: {
      crnEnabled,
      skDicEnabled,
      vatIdEnabled,
    },
  },
  myAccount: {
    features: {
      phoneCodeEnabled,
    },
  },
} = useAppConfig()

export const getDefaultAddress = () => ({
  street: '',
  city: '',
  regionCode: '',
  zip: '',
  country: '',
  companyName: '',
  ...(crnEnabled && { companyRegistrationNumber: '' }),
  ...(skDicEnabled && { skDic: '' }),
  ...(vatIdEnabled && { vatId: '' }),
  phoneNumber: '',
})

export const getDefaultUser = () => ({
  clientId: 0,
  firstName: '',
  lastName: '',
  email: '',
  loginEmail: '',
  correspondenceEmail: '',
  ...(phoneCodeEnabled && { phoneCode: '' }),
  phoneNumber: '',
  birthDate: '',
  sex: '',
  addresses: [],
  registrationCode: '',
  agreements: [],
  additionalParams: {},
  billingAddress: getDefaultAddress(),
  deliveryAddress: getDefaultAddress(),
  registrationAddress: getDefaultAddress(),
})

export const updateCurrentUser = async (user) => {
  try {
    const path = isSorpCustomerService ? '/client' : '/clients/me'

    const payload = mapUserToData(user)
    const { data, status } = await withAuth(client.put)(path, payload)

    return {
      data,
      ok: String(status).startsWith('2'),
      status,
    }
  } catch (e) {
    logger.error('updateCurrentUser(%o) failed: %s', user, e.message)

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const checkPhone = async (phone, code) => {
  try {
    const { data, status } = await client.post('/client/check-phone', {
      phone_number: phone,
      phone_prefix: code,
    })

    return {
      data,
      status,
      ...getStatusBasedOkResponse(status),
    }
  } catch (e) {
    logger.error('checkPhone(%o) failed: %s', code + phone, e.message)

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const updateCurrentUserAgreements = async (
  agreements,
) => {
  try {
    const path = isSorpCustomerService ? '/client/set-agreement' : '/clients/me/agreements'

    const payload = {
      agreements: mapAdvancedAgreements(agreements),
    }
    const { data, status } = await withAuth(client.put)(
      path,
      payload,
    )

    return {
      data,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'updateCurrentUserAgreements(%o) failed: %s',
      agreements,
      e.message,
    )

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const updateSorpCurrentUserAgreements = async (
  user,
) => {
  try {
    const path = isSorpCustomerService ? '/client/set-agreement' : '/clients/me/agreements'

    const payload = {
      agreements: mapAgreements(user),
    }

    const {
      data,
      status,
    } = await withAuth(client.put)(
      path,
      payload,
    )

    return {
      data,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'updateCurrentUserAgreements(%o) failed: %s',
      user,
      e.message,
    )

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const setMissingPersonalDetails = async (personalDetails) => {
  try {
    const payload = mapMissingPersonalDetailsToData(personalDetails)

    const path = isSorpCustomerService ? `/client/missing-personal-details/${personalDetails.clientId}` : `/clients/${personalDetails.clientId}/missing-personal-details`

    const { data, ok } = await client.put(
      path,
      payload,
    )

    if (isSorpCustomerService && !ok && data?.code === 10) {
      return {
        ok: true,
        doi: data.doi,
        userAlreadyExists: true,
        status: 200,
      }
    }

    // CEOS does not return correct error codes
    if (!ok && JSON.parse(JSON.parse(data?.detail)?.detail)?.err_code === 10) {
      return {
        ok: true,
        doi: data.doi,
        userAlreadyExists: true,
        status: 200,
      }
    }

    return {
      ok: true,
      doi: data.doi,
      status: 200,
    }
  } catch (e) {
    logger.error(
      'setMissingPersonalDetails(%s) failed: %s',
      personalDetails,
      e.message,
    )

    return {
      ok: false,
      doi: null,
      status: 500,
    }
  }
}

export const getMissingPersonalDetails = async (clientId) => {
  try {
    const { data, status } = await client.get(
      `/clients/${clientId}/missing-personal-details`,
    )

    return {
      ok: true,
      status,
      data,
    }
  } catch (e) {
    logger.error(
      'getMissingPersonalDetails(%s) failed: %s',
      clientId,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const removeUser = async () => {
  try {
    const { data, status } = await withAuth(client.delete)('/clients/me')

    return {
      ok: true,
      status,
      data,
    }
  } catch (e) {
    logger.error('removeUser failed: %s', e.message)

    return {
      ok: false,
      status: 500,
      data: null,
    }
  }
}

export const getCurrentUser = async (headers = {}) => {
  try {
    const path = isSorpCustomerService ? '/client' : '/clients/me'

    const { data, status, ok } = await withAuth(client.get)(path, { headers })

    const user = ok
      ? withDefault(getDefaultUser, await mapDataToUser(data))
      : getDefaultUser()

    if (!user.email) {
      removeTokens()
      return {
        ok: false,
        status: 403,
        user: getDefaultUser(),
      }
    }

    return {
      ok: true,
      status,
      user,
    }
  } catch (e) {
    logger.error('getCurrentUser() failed: %s', e.message)

    return {
      ok: false,
      status: 500,
      user: getDefaultUser(),
    }
  }
}

export const getUserById = async (clientId) => {
  try {
    const { data, status, ok } = await client.post('/clients/search', {
      clientId,
    })

    const user = ok
      ? withDefault(getDefaultUser, mapDataToUser(data))
      : getDefaultUser()

    return {
      ok,
      status,
      user,
    }
  } catch (e) {
    logger.error('getUserById(%s) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
      user: getDefaultUser(),
    }
  }
}

export const getCustomerGroupId = async (accountType) => {
  try {
    if (!accountType) {
      return {
        customerGroupId: null,
      }
    }

    const response = await customerGetGroupCodeQuery()
      .bind({ accountType })
      .get()

    return {
      customerGroupId: response.customer_group.id || null,
    }
  } catch (e) {
    logger.error('getCustomerGroupId(%s) failed: %s', accountType, e.message)

    return {
      customerGroupId: null,
    }
  }
}

export const signIntoLoyalty = async (clientId) => {
  try {
    const {
      status,
      data,
    } = await withAuth(client.post)('/cards/assign-loyalty-card', { clientId })

    const errorCode = status !== 200 && data.detail && JSON.parse(JSON.parse(data.detail).detail).err_code

    const errorMessages = {
      10: ERROR_MESSAGES.ERROR_CUSTOMER_RECORD_NOT_EXIST,
      11: ERROR_MESSAGES.ERROR_CLIENT_ID_MISSING,
      64: ERROR_MESSAGES.ERROR_CUSTOMER_ALREADY_HAS_CARD,
      65: ERROR_MESSAGES.ERROR_UPGRADE_REQUIRED_DATA_MISSING,
      66: ERROR_MESSAGES.ERROR_ASSIGNING_LOYALTY_CARD,
    }

    if (errorMessages[errorCode]) {
      return {
        ok: false,
        status,
        errorMessage: errorMessages[errorCode],
      }
    }

    return {
      ok: String(status).startsWith('2'),
      status: 200,
      errorMessage: '',
    }
  } catch (e) {
    logger.error('signIntoLoyalty(%o) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
      errorMessage: ERROR_MESSAGES.ERROR_SOMETHING_WENT_WRONG,
    }
  }
}

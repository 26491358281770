import { setState } from '@nsf/core/Store.js'

export default {
  namespaced: true,

  state: () => ({
    countMedicalProd: 0,
    countVetProd: 0,
  } as SuklWidgetState),

  getters: {
    getMedicalProdCount(state: SuklWidgetState): number {
      return state.countMedicalProd
    },

    getVetProdCount(state: SuklWidgetState): number {
      return state.countVetProd
    },
  },

  mutations: {
    setState,
  },

  actions: {
    addProduct(
      { commit, state }: { commit: (mutation: string, payload: Partial<SuklWidgetState>) => void, state: SuklWidgetState },
      attributeSetType: keyof SuklWidgetState,
    ) {
      commit('setState', { [attributeSetType]: state[attributeSetType] + 1 })
    },

    removeProduct(
      { commit, state }: { commit: (mutation: string, payload: Partial<SuklWidgetState>) => void, state: SuklWidgetState },
      attributeSetType: keyof SuklWidgetState,
    ) {
      commit('setState', { [attributeSetType]: state[attributeSetType] - 1 })
    },
  },
}

import { mapDataToModel } from '@nsf/core/Mapper.js'

const mapSellersPrices = (marketplaceQuote, quoteType) => {
  const { sellers: sourceSellersPrices } = marketplaceQuote?.marketplace_quote_totals ?? []

  if (sourceSellersPrices?.length) {
    const sellersPrices = sourceSellersPrices?.map((prices) => mapDataToModel(prices)) ?? []

    if (quoteType === 'mixed') {
      const drmaxPrices = {
        sellerId: null,
        prices: mapDataToModel(marketplaceQuote?.marketplace_quote_totals?.one_p_quote_prices ?? {}),
      }

      return [drmaxPrices, ...sellersPrices]
    }
    return [...sellersPrices]
  }
  return []
}

export default mapSellersPrices

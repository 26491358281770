import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      crmProxyUrl: '',
      customerServiceUrl: '',
      myAccountEndTimeDisabled: '',
      myAccountStartTimeDisabled: '',
      myAccountReturnServiceEnabled: false,
      myAccountReturnServiceURL: '',
      myAccountServerUrl: '',
      orderCancellationEnabled: false,
      placementServiceUrl: '',
      downloadInvoiceEnabled: false,
    },
  },
})

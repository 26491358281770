import logger from '@nsf/my-account/logger.js'
import { withAuth } from '../utils/authDecorator.js'
import { client, isSorpCustomerService } from '../clients/MyAccountClient.js'

const getCaptchaQuery = async (ctx) => {
  if (ctx?.$recaptcha && this.$config.public.recaptchaEnabled) {
    return `?captcha=${await ctx.$recaptcha.execute('submit')}`
  }

  return ''
}

export const checkEmail = async (
  email, ctx,
) => {
  try {
    const captchaQuery = await getCaptchaQuery(ctx)

    const path = isSorpCustomerService ? `/client/check-email${captchaQuery}` : `/clients/check/email/${email}${captchaQuery}`
    const method = isSorpCustomerService ? 'post' : 'get'
    const body = isSorpCustomerService ? { email } : {}

    const {
      data,
      status,
    } = await client[method](path, body)

    // H – unregistered client, no card, no login (only for info, it is not part of new registration porc.)
    // V – offline client with loyalty card, no login
    // E – pure Ecomm, no LP card, he has login
    // O – online registered client with loyalty card

    return {
      exists: !!data?.clientId,
      status,
      clientId: data?.clientId,
      type: data?.accountType ?? null,
    }
  } catch (e) {
    logger.error(
      'checkEmail(%s) failed: %s',
      email,
      e.message,
    )

    return {
      exists: true,
      status: 500,
      clientId: null,
      type: null,
    }
  }
}

export const setEmail = async (clientId, email) => {
  try {
    const { status, ok } = await client.put(`/clients/${clientId}`, { email })

    return {
      ok,
      status,
    }
  } catch (e) {
    logger.error(
      'setRegistrationEmail(%s) failed: %s',
      clientId,
      email,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const verifyEmail = async () => {
  try {
    const path = isSorpCustomerService ? '/client/send-verify-email' : '/clients/me/verify-email'

    const {
      data,
      status,
      ok,
    } = await withAuth(client.post)(path)

    let errorCode = null
    if (isSorpCustomerService) {
      errorCode = data?.code
    } else {
      errorCode = !ok && data.detail && JSON.parse(JSON.parse(data.detail).detail).err_code
    }

    if (errorCode === 21) {
      return {
        ok: false,
        status,
        isRegistrationEmailMissing: true,
      }
    }

    if (!ok) {
      throw new Error('Request failed')
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'resendRegistrationEmail failed: %s',
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

import {
  isEmpty, isNullish, isNumeric,
} from '@nsf/core/Utils.js'
import { formatPrice as genericFormatPrice } from '@nsf/utils/PriceUtils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { getProductsBySkus } from '@nsf/catalog/repositories/ProductRepository.js'
import { pushEventUserAddressChanged, pushEventUserDataChanged } from '@nsf/gtm/events/myAccount.js'
import dayjs from 'dayjs'
import { pushEventUserData } from '@nsf/gtm/events/userData.js'
import { checkEmail } from '../repositories/EmailRepository.js'

const {
  base: {
    currency,
  },
  myAccount: {
    features: {
      registrationAddressEnabled,
      companyItInvoicingEnabled,
    },
  },
  rootConfig: {
    global: {
      skDicEnabled,
      vatIdEnabled,
      crnEnabled,
    },
  },
} = useAppConfig()

const { public: { orderTrackingUrl } } = useRuntimeConfig()

export const ERROR_KEYS = [
  'doesNotContainForbiddenWords',
  'containsLowercase',
  'containsNumber',
  'doesNotContainPersonalData',
  'doesNotContainRepetitiveTerms',
  'containsUppercase',
  'maxLength',
  'minLength',
  'required',
]

export function formatPrice(
  amount, { returnZero = true } = {},
) {
  return isNumeric(amount) && (returnZero || amount !== 0)
    ? genericFormatPrice(
      amount,
      currency,
    )
    : null
}

export function formatNumber(value) {
  const number = toNumber(value)
  return number ? number.toLocaleString() : null
}

export function getOrderTrackingLink(
  order, user,
) {
  const contactData = order.addressesMatch ? order.billingAddress : order.deliveryAddress
  return `${orderTrackingUrl}/${order.orderId}?email=${contactData?.email ?? user.email}`
}

export const updatePurchaseItemFromProducts = (products) => (item) => {
  const product = products.find((product) => product.sku === item.sku)
  item.productName = product?.name ? product.name : item?.productName
  item.additionalData = {
    ...item.additionalData,
    ...product,
  }
}

export function toString(value) {
  return isNullOrUndefined(value) ? null : `${value}`
}

export function toBoolean(value) {
  return isNullOrUndefined(value) ? null : !!value
}

export function toNumber(value) {
  return isNaN(value) ? null : +value
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined
}

export function getScore(value) {
  const hasValue = value.$model && value.$model.length > 0

  if (!hasValue) {
    return 0
  }

  let score = 0
  const countActiveErrors = ERROR_KEYS.filter((key) => !value[key])

  score = countActiveErrors.length / 3

  if (score === 0) {
    return 3
  }

  if (score > 0 && score < 1) {
    return 2
  }

  if (score >= 1) {
    return 1
  }

  return 0
}

export async function getExistingSkus(skus) {
  if (!skus.length) {
    return ({ skus: [] })
  }
  const response = await getProductsBySkus(
    skus,
    { size: 9999 },
  )
  return { skus: response?.products.map((product) => product.sku) || [] }
}

export function getKeysObjectsComparison(
  originalObject, alteredObject,
) {
  const dataKeys = []
  if (isEmpty(originalObject) || isEmpty(alteredObject)) {
    return dataKeys
  }
  Object.keys(originalObject)
    .forEach((key) => {
      if (!isNullish(alteredObject[key]) && typeof alteredObject[key] !== 'object') {
        if (alteredObject[key] !== originalObject[key]) {
          dataKeys.push(key)
        }
      }
    })
  return dataKeys
}

export function findChangedAddresesAndPush(oldUser, newUser) {
  const addressesToCheck = [
    'deliveryAddress', 'billingAddress',
  ]

  if (registrationAddressEnabled) {
    addressesToCheck.push('registrationAddress')
  }

  addressesToCheck.forEach((item) => {
    const temporaryUserAddress = oldUser[item] ?? {}
    const formDataAddress = newUser[item] ?? {}

    const dataKeys = getKeysObjectsComparison(temporaryUserAddress, formDataAddress)

    if (dataKeys.length) {
      pushEventUserAddressChanged(
        oldUser.clientId,
        dataKeys,
        formDataAddress,
        item,
      )
    }
  })
}

export function findChangedPropertiesAndPush(oldUser, newUser) {
  const dataKeys = getKeysObjectsComparison(
    oldUser,
    newUser,
  )

  if (dataKeys.length) {
    pushEventUserDataChanged(
      newUser,
      dataKeys,
    )
  }
}

export async function transformUserEmailAndPush(
  userEmail, pushEventFc, ctx, ...additionalParams
) {
  const {
    exists, clientId,
  } = await checkEmail(userEmail, ctx)

  if (exists) {
    return pushEventFc(
      clientId,
      ...additionalParams,
    )
  }
  return pushEventFc(
    null,
    ...additionalParams,
  )
}

export const pushEventUserDataFn = (user, emailEshopValue = 'N') => {
  if (!user) {
    return
  }

  pushEventUserData(
    {
      id: user.clientId,
      firstname: user.firstName,
      surname: user.lastName,
      email: user.email,
    },
    {
      agreements: {
        emailEshop: user.agreements.find(({ channel }) => channel === 'EMAIL')?.value === emailEshopValue,
        postEshop: user.agreements.find(({ channel }) => channel === 'PPD')?.value === 'Y',
        smsEshop: user.agreements.find(({ channel }) => channel === 'SMS')?.value === 'Y',
      },
    },
  )
}

export function getBillingInformationForm(formData) {
  if (formData.isBillingInformationVisible) {
    return {
      ...formData.billingAddress,
      ...(companyItInvoicingEnabled ? { fiscalCode: '' } : {}),
    }
  }
  return {
    ...(companyItInvoicingEnabled ? { sdi: '', pec: '', fiscalCode: formData.billingAddress.fiscalCode } : {}),
    ...(skDicEnabled ? { skDic: '' } : {}),
    ...(vatIdEnabled ? { vatId: '' } : {}),
    ...(crnEnabled ? { companyRegistrationNumber: '' } : {}),
    companyName: '',
    ...(registrationAddressEnabled
      ? {
          streetNumber: '',
          street: '',
          city: '',
          regionCode: '',
          zip: '',
        }
      : {}),
  }
}

export const formatDate = (date) => dayjs(date).format('DD. MM. YYYY')

export const getStatusBasedOkResponse = (status) => ({ ok: String(status).startsWith('2') })

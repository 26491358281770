import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    carrierCodes: {
      timeSlotDelivery: {
        timeSlotDeliveryCarrierCode,
      },
    },
    features: {
      timeSlotDeliveryEnabled,
    },
  },
} = useAppConfig()

const mapDataToDeliveryRoutes = (routes) => mapDataToModel(routes).map((route) => ({
  ...route,
  dropTimeStart: route.dropTimeStart ? new Date(route.dropTimeStart.replace(/ /, 'T').replace(/Z?$/, 'Z')) : null,
  dropTimeEnd: route.dropTimeEnd ? new Date(route.dropTimeEnd.replace(/ /, 'T').replace(/Z?$/, 'Z')) : null,
  cutoffTime: route.cutoffTime ? new Date(route.cutoffTime.replace(/ /, 'T').replace(/Z?$/, 'Z')) : null,
  cutoffTimePlaceOrder: route.cutoffTimePlaceOrder ? new Date(route.cutoffTimePlaceOrder.replace(/ /, 'T').replace(/Z?$/, 'Z')) : null,
}))

export const mapDataToTimeSlotDelivery = (data) => ({
  ...data,
  defaultDropTimeStart: data.defaultDropTimeStart ? new Date(data.defaultDropTimeStart.replace(/ /, 'T').replace(/Z?$/, 'Z')) : null,
  routes: mapDataToDeliveryRoutes(data.routes ?? []),
})

export const mapDataToShippingMethod = (data, translations = {}) => {
  const method = mapDataToModel(data, translations)
  if (timeSlotDeliveryEnabled) {
    const isTimeslotMethod = method.carrierCode === timeSlotDeliveryCarrierCode
    if (isTimeslotMethod) {
      method.timeSlotDelivery = mapDataToTimeSlotDelivery(method.timeSlotDelivery ?? {})
    }
  }
  return method
}

export const mapDataToShippingMethods = (data, translations = {}) => data.map(
  (method) => mapDataToShippingMethod(method, translations),
)

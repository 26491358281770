import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
export var scrollToSavedPos = function scrollToSavedPos() {
  var _savedPositions$find;
  var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var usePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var searchPath = usePath ? useRoute().path : useRoute().fullPath;
  var savedPositions = useStore().state.catalog.savedPositions;
  var savedPosition = (_savedPositions$find = savedPositions.find(function (_ref) {
    var fullPath = _ref.fullPath;
    return fullPath === searchPath;
  })) !== null && _savedPositions$find !== void 0 ? _savedPositions$find : 0;
  if (savedPosition) {
    var position = savedPosition.position;
    setTimeout(function () {
      window.scrollTo(0, position + offset);
    }, 0);
  }
};
export var saveScrollPosition = function saveScrollPosition() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : useRoute().fullPath;
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var position = window.scrollY;
  useStore().commit("catalog/savePosition", {
    fullPath: path,
    position: position - offset
  });
};
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    myAccountSorpEnabled,
  },
} = useRuntimeConfig()

const {
  base: {
    currency: defaultCurrency,
    currencyLocale: currencyLocaleConfig,
  },
} = useAppConfig()

export const getFinalPrice = (product, isLoggedIn, { isFullUserAccount, isLimitedUserAccount }) => {
  const hasFinalPrice = !!product.finalPrice || !!product.finalPriceLogged
  const priceForLoggedAvailable = !!product.finalPriceLogged && product.finalPriceLogged < product.finalPrice
  const priceForFullAvailable = !!product.finalPriceFull && product.finalPriceFull < product.finalPrice
  const priceForLimitedAvailable = !!product.finalPriceLimited && product.finalPriceLimited < product.finalPrice

  if (!hasFinalPrice) {
    return 0
  }

  if (isLoggedIn) {
    if (myAccountSorpEnabled && isFullUserAccount && priceForFullAvailable) {
      return product.finalPriceFull
    }

    if (myAccountSorpEnabled && isLimitedUserAccount && priceForLimitedAvailable) {
      return product.finalPriceLimited
    }

    if (priceForLoggedAvailable) {
      return product.finalPriceLogged
    }
  }

  return product.finalPrice
}

export const getFinalPriceExclVat = (product, isLoggedIn, { isFullUserAccount, isLimitedUserAccount }) => {
  const hasFinalPrice = !!product.finalPriceExclVat || !!product.finalPriceExclVatLogged
  const priceForLoggedAvailable = !!product.finalPriceExclVatLogged
    && product.finalPriceExclVatLogged < product.finalPriceExclVat
  const priceForFullAvailable = !!product.finalPriceExclVatFull
    && product.finalPriceExclVatFull < product.finalPriceExclVat
  const priceForLimitedAvailable = !!product.finalPriceExclVatLimited
    && product.finalPriceExclVatLimited < product.finalPriceExclVat

  if (!hasFinalPrice) {
    return 0
  }

  if (isLoggedIn) {
    if (myAccountSorpEnabled && isFullUserAccount && priceForFullAvailable) {
      return product.finalPriceExclVatFull
    }

    if (myAccountSorpEnabled && isLimitedUserAccount && priceForLimitedAvailable) {
      return product.finalPriceExclVatLimited
    }

    if (priceForLoggedAvailable) {
      return product.finalPriceExclVatLogged
    }
  }

  return product.finalPriceExclVat
}

export const getCurrency = (value, currency) => {
  const price = Number(value)

  switch (currency) {
    case 'CZK': return 'Kč'
    case 'PLN': return 'zł'
    case 'EUR': return '€'
    case 'RON':
      if (price === 1) {
        return 'Leu'
      }
      return 'Lei'

    default: return currency
  }
}

/**
 * Specifies custom decimal settings.
 *
 * @typedef {number[]} CustomDecimals
 * @property {number} 0 - The maximum decimal value.
 * @property {number} [1] - The minimum decimal value. If not specified, the maximum value is used.
 */

/**
 * Currency-based price formatter
 *
 * @param {string | number} value
 * @param {string} currency
 * @param {boolean} addCurrency
 * @param {CustomDecimals} customDecimals - An array containing the minimum and maximum decimal values.
 * @returns {string}
 */

export const formatPrice = (value, currency, addCurrency = true, customDecimals = []) => {
  const price = Number(value)

  if (currency) {
    return `${localizePrice(price, currency, customDecimals)} ${addCurrency ? getCurrency(price, currency) : ''}`
  }
  return localizePrice(price, null, customDecimals)
}

function localizePrice(value, currency, customDecimals) {
  const localizeCurrency = currency || defaultCurrency
  const decimalNumbers = getDecimalNumbersForCurrency(localizeCurrency)

  const options = {
    style: 'decimal',
    currency: localizeCurrency,
    minimumFractionDigits: customDecimals.length ? customDecimals[1] || customDecimals[0] : decimalNumbers,
    maximumFractionDigits: customDecimals.length ? customDecimals[0] : decimalNumbers,
  }

  const currencyLocale = currencyLocaleConfig[localizeCurrency]
  const numberFormat = new Intl.NumberFormat(currencyLocale || 'en-US', options)
  return numberFormat.format(value)
}

function getDecimalNumbersForCurrency(currency) {
  switch (currency) {
    case 'CZK':
      return 0
    case 'EUR':
    case 'RON':
    case 'PLN':
      return 2
    default:
      return 2
  }
}

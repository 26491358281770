import logger from '@nsf/product-relationship-loader-persoo/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  productRelationshipLoaderPersoo: {
    persooAlternativesEnabled,
    persooLastViewedEnabled,
    persooNotFoundEnabled,
    persooPreBasketEnabled,
    persooNotFoundLimit,
    persooPreBasketLimit,
  },
} = useAppConfig()

/**
 * Get products ids according to given product id and Persoo method.
 * @async
 * @param method {string} Method identifier to be used for sending.
 * @param prop {string} A prop to get from.
 * @param options {{itemID: number, BasketItems: [number] }?}
 * @return {{ products: number[] }} Ids of the products.
 */
async function getPersooProducts(method, prop, options = {}, limit = 9001) {
  let timer
  try {
    const productIds = await Promise.race([
      new Promise((resolve) => {
        window.persoo('send', method, options, () => {
          const data = window.persoo.rawData
          resolve(data ? data[prop] || [] : [])
        })
      }),
      // eslint-disable-next-line no-return-assign
      new Promise((resolve, reject) => timer = setTimeout(() => reject({ message: 'Loading timed out' }), 2000)),
    ])
    clearTimeout(timer)

    return {
      productIds: productIds.slice(0, limit).map((id) => parseInt(id)),
    }
  } catch (e) {
    logger.error(`${method}${options?.itemID ? `(${options.itemID})` : ''} failed: %s`, e?.message)
    clearTimeout(timer)

    return {
      productIds: [],
    }
  }
}

/**
 * Get related products ids according to given product id.
 * @async
 * @param id {number} Related product id. Can be null for specific methods.
 * @return {{products: number[]}}
 */
export const getAlternativesForProduct = async (id) => {
  if (persooAlternativesEnabled) {
    return getPersooProducts('getRawAlternatives', 'alternatives', { itemID: id })
  }
}

/**
 * Get last viewed product ids.
 * @async
 * @return {{products: number[]}}
 */
export const getLastViewedProducts = async () => {
  if (persooLastViewedEnabled) {
    return getPersooProducts('getRawLastViewed', 'lastViewed')
  }
}

/**
 * Get fulltext-result product ids.
 * @async
 * @return {{products: number[]}}
 */
export const getNoResultsProducts = async () => getPersooProducts('getRawNoResults', 'noResultsSearch')

/**
 * Get recommended pre-basket products of given product id
 * @async
 * @return {{products: number[]}}
 */
export const getPreBasketProducts = async (id) => {
  if (persooPreBasketEnabled) {
    return getPersooProducts('getRawPrebasket', 'prebasket', { itemID: id }, persooPreBasketLimit)
  }
}

/**
 * Get recommended basket products of given product ids
 * @async
 * @return {{products: number[]}}
 */
export const getBasketProducts = async (ids) => {
  if (persooPreBasketEnabled) {
    return getPersooProducts('getRawBasket', 'basket', { basketItems: ids })
  }
}

/**
 * Get recommended products when route is not found
 * @async
 * @return {{products: number[]}}
 */
export const get404NotFoundProducts = async () => {
  if (persooNotFoundEnabled) {
    return getPersooProducts('getRaw404', 'notfound', persooNotFoundLimit)
  }
}

import logger from '@nsf/my-account/logger.js'
import { mapFavoritePharmaciesToData } from '../mappers/FavoritesMapper.js'
import { withAuth } from '../utils/authDecorator.js'
import { client, isSorpCustomerService } from '../clients/MyAccountClient.js'

const path = isSorpCustomerService ? '/client/metadata/favoritePharmacies' : '/clients/me/metadata/favoritePharmacies'

export const getFavoritePharmaciesUserData = async () => {
  try {
    const {
      data, ok,
    } = await withAuth(client.get)(path)
    if (ok) {
      return {
        data: mapFavoritePharmaciesToData(data.data),
        version: data.version,
      }
    }

    if (data.message === 'METADATA_NOT_FOUND') {
      return setFavoritePharmaciesUserData()
    }
    throw new Error(data.message)
  } catch (e) {
    logger.error(
      'getFavoritePharmaciesUserData failed: %s',
      e.message,
    )

    return {
      version: 0,
      data: {
        pharmacies: [],
      },
    }
  }
}

export const setFavoritePharmaciesUserData = async (obj = {
  data: { pharmacies: [] },
  version: 1,
}) => {
  try {
    const {
      data, ok,
    } = await withAuth(client.post)(
      path,
      obj,
    )

    if (ok) {
      return {
        data: mapFavoritePharmaciesToData(data.data),
        version: data.version,
      }
    }

    if (data.message === 'METADATA_NEWER_VERSION_EXISTS') {
      const error = new Error(data.message)
      error.data = data
      throw error
    }
  } catch (e) {
    logger.error(
      'setFavoritePharmaciesUserData failed: %s',
      e.message,
    )

    if (e.message === 'METADATA_NEWER_VERSION_EXISTS') {
      return {
        data: mapFavoritePharmaciesToData(e.data.detail.data),
        version: e.data.detail.version,
      }
    }

    return {
      version: 0,
      data: null,
    }
  }
}

import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementBanners } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementBannerWidgetSliderLimit,
  },
} = useAppConfig()

export default async function (pageType = null) {
  const userId = ''
  const config = this?.config ?? {
    limit: placementBannerWidgetSliderLimit,
    pageType,
  }
  return await getPlacementBanners('banner-widget-slider', userId, config, this?.ctx)
}
